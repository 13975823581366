import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ResidueService, TablePriceService} from "../../services/services.service";
import {Residue, TablePrice} from "../../models/models.model";
import {Observable, Subject} from "rxjs";
import {map, startWith, takeUntil} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {MESSAGES} from "../../app.constant";
import {ToastService} from "../../services/toast.service";
import {TitlePageService} from "../../shared/title-page.service";

@Component({
  selector: 'app-table-price',
  templateUrl: './table-price.component.html',
  styleUrls: ['./table-price.component.scss']
})
export class TablePriceComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public dataSource = new MatTableDataSource<TablePrice>()
  public formGroup: FormGroup;
  public object = new TablePrice();
  public residueList = new Array<Residue>();
  public filteredResidues: Observable<Residue[]>;
  public id_client: string;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  public displayedColumns = ['name', 'price', 'price_sell'];
  private unsubscribe = new Subject();

  constructor(
      public formBuilder: FormBuilder,
      public tablePriceService: TablePriceService,
      private residueService: ResidueService,
      private activatedRoute: ActivatedRoute,
      public toast: ToastService,
      private title: TitlePageService,
  ) {
  }

  ngOnInit() {
    this.createFormGroup();
    this.getActiveResidues();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    if (this.activatedRoute.snapshot.params["action"] !== "new") {
      this.id_client = this.activatedRoute.snapshot.params["action"];
      if (this.id_client === 'default') {
        this.id_client = null;
        this.title.name.next('Tabela de precos');

      }
      this.getTablePrice();
    }

  }


  public createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      residue: [],
      id: [],
      price: [],
      price_sell: [],
      customer: [],
      active: [true]
    });
  }

  getActiveResidues() {
    this.residueService.clearParameter();
    this.residueService.addParameter('active', true);
    return this.residueService.getAll().subscribe(result => {
      this.residueList = result;
      this.filteredResidues = this.formGroup.controls.residue.valueChanges
          .pipe(
              startWith(''),
              map(value => value ? this._filterResidue(value) : this.residueList.slice())
          );
    });
  }

  private _filterResidue(name: string): Residue[] {
    let filterValue = name;
    if (typeof name === 'string') {
      filterValue = name.toLowerCase();
    }
    return this.residueList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  public savePrice(element?) {
    this.formGroup.controls.customer.setValue(this.id_client);
    this.object = element ? element : this.formGroup.getRawValue();
    if (this.object.price < this.object.price_sell || this.object.price_sell == 0) {
      const formData = new FormData();
      Object.keys(this.object).forEach(key => {
        const value = this.object[key];
        if (value !== null && typeof value === 'object') {
          formData.append(key, value === undefined ? '' : value.id);
        } else {
          formData.append(key, value === null || value === undefined ? '' : value);
        }
      });
      if (this.object['id']) {
        this.tablePriceService.update(this.object['id'], formData).subscribe(success => {
          this.getTablePrice();
        });
      } else {
        this.save(formData);
      }
    } else {
      this.toast.error('Preço', "Preço de venda menor que o de compra");
      element = 0
    }
  }

  private save(aObject): void {
    this.tablePriceService.save(aObject)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
            response => {
              this.toast.success(MESSAGES.success_title, MESSAGES.saved_successfully);
              this.getTablePrice();
            }
        );
  }

  displayFnR(residue?: Residue): string | undefined {
    return residue ? residue.name : undefined;
  }

  getTablePrice() {
    this.tablePriceService.clearParameter();
    this.tablePriceService.addParameter('customer', this.id_client);
    this.tablePriceService.getAll().subscribe(result => {
      this.dataSource.data = result;
    });
  }

  public generateAll() {
    this.tablePriceService.clearParameter();
    this.tablePriceService.addParameter('client_id', this.id_client);
    this.tablePriceService.getFromListRoute('generate_all_products')
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(success => this.getTablePrice());
  }

  public tab($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      $event.preventDefault();
      this.toast.info("Tecla Tab", "Utilizar a tecla tab para ir ao próximo valor")
    }
  }
}
