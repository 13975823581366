import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DashboardComponent} from './dashboard/dashboard.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {LayoutModule} from '@angular/cdk/layout';
import {MainMenuComponent} from './main-menu/main-menu.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {TicketListComponent} from './ticket-list/ticket-list.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {TicketComponent} from './ticket-list/ticket/ticket.component';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSliderModule} from '@angular/material/slider';
import {ToastService} from './services/toast.service';
import {ToastrModule} from 'ngx-toastr';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {
  CustomerService,
  FileUploadService,
  GroupTypeService,
  LicenseService,
  ResidueService,
  TablePriceService,
  TicketService,
  TrucksService,
  UserService,
  CustomerViewLicenseService
} from './services/services.service';
import {DialogComponent} from "./shared/dialog/dialog.component";
import {LoginComponent} from './login/login.component';
import {AppGuard} from "./app.guard";
import {AuthInterceptor} from "./shared/auth.interceptor";
import {AuthService} from "./services/auth.service";
import {TotalWeightComponent} from './dashboard/total-weight/total-weight.component';
import {NgxEchartsModule} from "ngx-echarts";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {ClientListComponent} from './client-list/client-list.component';
import {ClientComponent} from "./client-list/client/client.component";
import {FullLoadComponent} from "./full-load/full-load.component";
import {FullLoadService} from "./services/full-load.service";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {TablePriceComponent} from './client-list/table-price/table-price.component';
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {MatDialogModule} from "@angular/material/dialog";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {registerLocaleData} from "@angular/common";
import localePt from '@angular/common/locales/pt'
import {TotalWeightSaleComponent} from "./dashboard/total-weight-sale/total-weight-sale.component";
import {TicketProductComponent} from './ticket-list/ticket/ticket-produt/ticket-product.component';
import {WeightPerCustomerComponent} from './dashboard/weight-per-customer/weight-per-customer.component';
import {CurrencyMaskInputMode, NgxCurrencyModule} from "ngx-currency";
import {CUSTOM_DATE_FORMAT} from "./app.constant";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {DateFormatDirective} from "./shared/date-format.directive";
import {TitlePageService} from "./shared/title-page.service";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {ProductListComponent} from './product-list/product-list.component';
import {ProductComponent} from './product-list/product/product.component';
import {ResiduesPerClientComponent} from './dashboard/residues-per-client/residues-per-client.component';
import {LicenseListComponent} from './license-list/license-list.component';
import {LicenseComponent} from './license-list/license/license.component';
import {MatTabsModule} from "@angular/material/tabs";
import {ResiduesClientMonthsComponent} from "./dashboard/residue-client-months/residue-client-months.component";
import {ClientLicenseComponent} from "./client-list/client-license/client-license.component";
import {ClientTruckComponent} from "./client-list/client-truck/client-truck.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatTooltipModule} from "@angular/material/tooltip";
import {ClientUserComponent} from './client-list/client-user/client-user.component';
import {DestinationCertComponent} from "./destination-cert/destination-cert.component";
import { ClientProviderComponent } from './client-list/client-provider/client-provider.component';
import {TicketsMonthsComponent} from "./tickets-months/tickets-months.component";
import { SaleResiduesClientComponent } from './dashboard/sale-residues-client/sale-residues-client.component';

registerLocaleData(localePt, 'pt-br');

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    MainMenuComponent,
    TicketListComponent,
    TicketComponent,
    DialogComponent,
    LoginComponent,
    TotalWeightComponent,
    ClientComponent,
    ClientListComponent,
    FullLoadComponent,
    TablePriceComponent,
    TotalWeightSaleComponent,
    TicketProductComponent,
    WeightPerCustomerComponent,
    DateFormatDirective,
    ProductListComponent,
    ProductComponent,
    ResiduesPerClientComponent,
    LicenseListComponent,
    LicenseComponent,
    ClientTruckComponent,
    ClientLicenseComponent,
    ResiduesClientMonthsComponent,
    ClientUserComponent,
    DestinationCertComponent,
    ClientProviderComponent,
    TicketsMonthsComponent,
    SaleResiduesClientComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatDialogModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    ToastrModule.forRoot(),
    NgxEchartsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FlexModule,
    FlexLayoutModule,
    FormsModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    MatButtonToggleModule,
    MatTabsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatIconModule
  ],
  providers: [
    AuthService,
    ToastService,
    CustomerService,
    ResidueService,
    TrucksService,
    AppGuard,
    FullLoadService,
    TablePriceService,
    TicketService,
    GroupTypeService,
    TitlePageService,
    LicenseService,
    UserService,
    FileUploadService,
    CustomerViewLicenseService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {provide: MAT_DATE_LOCALE, useValue: 'pt-br'},
    {provide: LOCALE_ID, useValue: 'pt-br'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT},
  ],
  entryComponents: [
    DialogComponent,
  ],
  bootstrap: [AppComponent]
})


export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
  }
}
