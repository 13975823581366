import {Customer, GroupType, License, Residue, TablePrice, Truck} from './../models/models.model';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from '../app.urls';
import { User } from '../models/user';

@Injectable()
export class CustomerService extends BaseService<Customer> {
  constructor(http: HttpClient) {
    super(http, URLS.CUSTOMER);
  }
}

@Injectable()
export class ResidueService extends BaseService<Residue> {
  constructor(http: HttpClient) {
    super(http, URLS.RESIDUE);
  }
}

@Injectable()
export class TrucksService extends BaseService<Truck> {
  constructor(http: HttpClient) {
    super(http, URLS.TRUCKS);
  }
}

@Injectable()
export class TablePriceService extends BaseService<TablePrice>{
  constructor(http: HttpClient) {
    super(http, URLS.TABLE_PRICE)
  }
}

@Injectable()
export class TicketService extends BaseService<TablePrice>{
  constructor(http: HttpClient) {
    super(http, URLS.TICKETS)
  }
}

@Injectable()
export class GroupTypeService extends BaseService<GroupType>{
  constructor(http: HttpClient) {
    super(http, URLS.GROUP_TYPE)
  }
}

@Injectable()
export class LicenseService extends BaseService<License>{
  constructor(http: HttpClient) {
    super(http, URLS.LICENSE)
  }
}

@Injectable()
export class UserService extends BaseService<User>{
  constructor(http: HttpClient) {
    super(http, URLS.USER)
  }
}

@Injectable()
export class FileUploadService extends BaseService<any>{
  constructor(http: HttpClient) {
    super(http, URLS.UPLOAD);
  }

}
@Injectable()
export class CustomerViewLicenseService extends BaseService<any>{
  constructor(http: HttpClient) {
    super(http, URLS.CUSTOMER_VIEW_LICENSE);
  }

}
