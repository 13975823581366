import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {MatTable} from '@angular/material/table';
import {TicketListItem} from './ticket-list-datasource';
import {BaseComponent} from '../base.component';
import {URLS} from '../app.urls';
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.css']
})
export class TicketListComponent extends BaseComponent<TicketListItem> implements AfterViewInit, OnInit {

  @ViewChild(MatTable) table: MatTable<TicketListItem>;
  public user: any;
  private authService: AuthService;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  public displayedColumns: any;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.table.dataSource = this.dataSource;
  }

  constructor(
      public injector: Injector,
  ) {
    super(injector, {
      endpoint: URLS.TICKETS,
      pagination: true,
      searchOnInit: false,
      keepFilters: true,
      title: 'Tickets',
    });
    this.authService = injector.get(AuthService);
    this.user = this.authService.user;
  }

  ngOnInit(callback?: () => void) {
    super.ngOnInit();
    setTimeout(() => {
      this.isClient();
    });
  }

  public clearField(formControl) {
    formControl.setValue('')
  }

  public createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      customer: [null],
      customer_id: [null],
      no_packing_list: [null],
      mtr: [null],
      ticket_number: [null],
      service_type: [null],
      date_weight_final_before: [null],
      date_weight_final_after: [null],
      is_service: [null],
      service: [null],
    });
  }

  private isClient() {
    this.displayedColumns = this.user.is_staff ? ['no_packing_list', 'mtr', 'ticket_number', 'customer_name', 'weight', 'date_weight_final', 'action'] :
        ['no_packing_list', 'mtr', 'ticket_number', 'customer_name', 'weight', 'date_weight_final', 'download'];
    if (this.user.user_obj.users.length) {
      this.formGroup.get('customer_id').setValue(this.user.user_obj.users[0]);
      this.formGroup.get('customer').disable();
    }
    this.service.addParameters(this.formGroup);
    super.search(this.paginator);
  }

  public searchButton(event, sort): void {
    this.service.clearParameter();
    if (this.formGroup.get('is_service').value){
      this.formGroup.get('service').setValue((this.formGroup.get('is_service').value ? 'True' : 'False'));
    }
    this.service.addParameters(this.formGroup);
    super.search(event, sort);
  }

  public download(element) {
    if (element.ticket_file.length) {
      element.ticket_file.forEach(item => {
        window.open(item.file, "arquivo", "height=400,width=400");
      })
    }
  }

}
