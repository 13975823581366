<div fxLayout="column" fxLayoutGap="20px">
    <div>
        <button mat-button color="primary" [routerLink]="['/license-list']">
            <mat-icon class="ic-back">chevron_left</mat-icon>
            VOLTAR
        </button>
    </div>

    <form [formGroup]="formGroup" novalidate (ngSubmit)="saveOrUpdateFormData()">
        <mat-card>
            <mat-card-content>
                <div class="row" fxLayoutGap="20px">
                    <mat-form-field>
                        <input id="number" matInput type="text" formControlName="number" placeholder="Numero">
                    </mat-form-field>
                    <mat-form-field fxFlex>
                        <input id="id" matInput type="text" placeholder="Identificação" formControlName="name" >
                    </mat-form-field>
                    <mat-form-field fxFlex="200px">
                        <mat-label>Data expedição</mat-label>
                        <input id="data_registro_inicial" dateFormat="YYYY-MM-DD" matInput [matDatepicker]="picker"
                               formControlName="dt_shipping">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field fxFlex="200px">
                        <mat-label>Data vencimento</mat-label>
                        <input id="data_registro_vencimento" dateFormat="YYYY-MM-DD" matInput [matDatepicker]="picker2"
                               formControlName="dt_due">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field fxFlex>
                        <input id="days_notification" matInput type="number" placeholder="Dias para notificação" formControlName="validity_days" >
                    </mat-form-field>

                </div>
                <div class="row">
                    <div fxLayour="column">
                        <div class="row form-group">
                            <div class="col">
                                <p>CARREGAR NOVO ARQUIVO</p>
                                <div class="line"></div>
                            </div>
                        </div>
                        <div class="row">
                            <mat-card-actions align="start" class="col">
                                <input type="file" #fileInput id="file" hidden="true"
                                       (change)="handleFileInput($event.target.files)">
                                <button (click)="fileInput.click()" type="button" mat-raised-button color="accent">Escolher
                                    arquivo</button>
                            </mat-card-actions>
                        </div>
                        <div class="mat-elevation-z1 chosen-file row" *ngIf="fileToUpload != null">
                            <div class="col">
                                <p>{{ fileToUpload.name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions align="end">
                <button mat-raised-button color="primary" [routerLink]="['/license-list']">Cancelar</button>
                <button [disabled]="formGroup.invalid" mat-raised-button color="accent" type="submit">Salvar</button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>
