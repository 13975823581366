<mat-card>
    <mat-card-header fxLayout="start center">
        <mat-card-title class="title" fxLayoutAlign="space-between center">
            <div>Residuos</div>

            <mat-button-toggle-group #typebuy="matButtonToggleGroup" [value]="type"
                                     *ngIf="user.user_obj.is_staff"
                                     (change)="changeType(typebuy.value)">
                <mat-button-toggle value="K">Kg</mat-button-toggle>
                <mat-button-toggle value="R">R$</mat-button-toggle>
            </mat-button-toggle-group>
        </mat-card-title>
    </mat-card-header>

    <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex [formGroup]="formGroup" *ngIf="user.user_obj.is_staff">
            <input #clientInput matInput type="text" placeholder="Cliente" formControlName="customer" [matAutocomplete]="auto">
            <button  mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearField(formGroup.controls['customer'])">
                <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>

        </mat-form-field>
        <mat-form-field fxFlex>
            <mat-label>Data inicial</mat-label>
            <input id="ccdtinicial" matInput [matDatepicker]="picker" [(ngModel)]="dtInicial">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field fxFlex>
            <mat-label>Data final</mat-label>
            <input id="ccdtfinal" matInput [matDatepicker]="picker2" [(ngModel)]="dtFinal">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
        <div>
            <button mat-icon-button class="bt-search" type="button" (click)="getData()">
                <i class="material-icons">search</i>
            </button>
        </div>
    </div>

    <div fxLayout="row" *ngIf="showChart">
        <div fxLayout="column" class="border" fxFlex [theme]="theme" echarts [options]="weightOption"></div>
    </div>
</mat-card>
