import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../base.component';
import {URLS} from '../../app.urls';
import {takeUntil} from 'rxjs/operators';
import {WeightPerCustomerChart} from '../../dtos/weight-per-customer-chart';
import {GroupType} from "../../models/models.model";
import {GroupTypeService} from "../../services/services.service";
import {Utils} from "../../shared/utils";
import EChartOption = echarts.EChartOption;
import {Moment} from "moment";
import {SERVICE_TYPES} from "../../app.constant";
import {MatButtonToggleGroup} from "@angular/material/button-toggle";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-weight-per-customer',
  templateUrl: './weight-per-customer.component.html',
  styleUrls: ['./weight-per-customer.component.scss']
})
export class WeightPerCustomerComponent extends BaseComponent<WeightPerCustomerChart> implements OnInit {

  public showChart = false;
  public weightOption: EChartOption;
  public theme = 'macarons';
  public groupType = null;
  public groupTypes = new Array<GroupType>();
  public dtInicial: any;
  public dtFinal: any;
  public operation: string;
  private color: string;
  public type = 'K';
  public user: any;
  // R= valor / K = peso
  @ViewChild("typebuy", {static: false}) public typebuy: MatButtonToggleGroup;

  constructor(
      public injector: Injector,
      private groupTypeService: GroupTypeService,
      private authService: AuthService,
  ) {
    super(injector, {endpoint: URLS.TICKET_PRODUCT});
  }

  ngOnInit(): void {
    this.user = this.authService.user;
    super.ngOnInit();
    this.getGroupProducts().subscribe(result => {
      this.groupTypes = result;
      this.groupType = result[1];
      this.operation = SERVICE_TYPES.COMPRA;
      this.getData();
    })

  }
  public changeType(value) {
    this.type = value
    this.getData();
  }

  getGroupProducts() {
    this.groupTypeService.clearParameter();
    this.groupTypeService.addParameter('active', true);
    return this.groupTypeService.getAll();
  }

  public getData(): void {
    switch (this.groupType.name) {
      case 'LIXO':
        this.color = '#8f5e30';
        break;
      case 'PAPELÃO':
        this.color = '#2856a3';
        break;
      case 'PLASTICO':
        this.color = '#d12b17';
        break;
      case 'PLÁSTICO':
        this.color = '#d12b17';
        break;
      case 'METAIS':
        this.color = '#eab205';
        break;
      case 'PALLET':
        this.color = '#c3aa6d';
        break;
      default:
        this.color = '#00ff0c';
    }
    this.service.clearParameter();
    this.service.addParameter('group_type', this.groupType.id);
    this.service.addParameter('by_weight', this.type==='K')
    this.service.addParameter('op_type', this.operation);
    if (this.user.user_obj.users.length){
      this.service.addParameter('customer', this.user.user_obj.users[0]);
    }
    if (this.dtInicial) {
      this.service.addParameter('dt_inicial', Utils.getDateToStringRequest(this.dtInicial._d));
    }
    if (this.dtFinal) {
      this.service.addParameter('dt_final', Utils.getDateToStringRequest(this.dtFinal._d));
    }
    this.service.getFromListRoute<WeightPerCustomerChart[]>('find_report')
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(response => {
          this.createChart(response);
        });
  }

  public createChart(response: WeightPerCustomerChart[]): void {
    const dataAxis = [];
    const dataSeries = [];

    response.forEach(t => {
      dataAxis.push(t.ticket__customer__name.toUpperCase().split(" ")[0]);
      dataSeries.push(t.total);
    });

    let unit = this.type === 'K'? ' Kg' : ' R$';

    this.weightOption = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: function (params, ticket, callback) {
          return params[0].value.toLocaleString('pt') + unit;
        }
      },
      toolbox: {
        feature: {
          saveAsImage: {
            show: true,
            title: 'download'
          },
        },
        top: 'top',
        right: 15,
        orient: 'vertical',
        itemGap: 20
      },
      xAxis: {
        type: 'category',
        data: dataAxis,
        axisLabel: {
          rotate: 45
        }
      },
      yAxis: {
        type: 'value',
        splitArea: {
          show: true,
          areaStyle: {
            color: ['#f8f8f8', '#c7c3c3']
          }
        }
      },
      series: [{
        type: 'bar',
        data: dataSeries,
      }],
      color: [this.color],
    };

    this.showChart = response.length > 0;
  }

  createFormGroup(): void {
  }

}
