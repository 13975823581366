import {AfterViewInit, Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {Customer} from "../../models/models.model";
import {EChartOption} from "echarts";
import {MatButtonToggleGroup} from "@angular/material/button-toggle";
import {URLS} from "../../app.urls";
import {BaseComponent} from "../../base.component";
import {ResiduesPerCustomerChart} from "../../dtos/residues-per-custumert";
import {Utils} from "../../shared/utils";
import {map, startWith, takeUntil} from "rxjs/operators";
import {CustomerService} from "../../services/services.service";
import {Observable} from "rxjs";
import {Validators} from "@angular/forms";
import {SERVICE_TYPES} from "../../app.constant";
import {AuthService} from 'src/app/services/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-residues-per-client',
  templateUrl: './residues-per-client.component.html',
  styleUrls: ['./residues-per-client.component.scss']
})
export class ResiduesPerClientComponent extends BaseComponent<ResiduesPerCustomerChart> implements OnInit, AfterViewInit {

  public showChart = false;
  public weightOption: EChartOption;
  public theme = 'macarons';
  public dtInicial: any;
  public dtFinal: any;
  public operation: string;
  private color: string;
  public type = 'K';
  public user: any;
  public customerList = new Array<Customer>();
  public filteredOptions: Observable<Customer[]>;
  public sumAll = 0;

  // R= valor / K = peso
  @ViewChild("typebuy", {static: false}) public typebuy: MatButtonToggleGroup;

  constructor(
    public injector: Injector,
    private customerService: CustomerService,
    private authService: AuthService,
  ) {
    super(injector, {endpoint: URLS.TICKET_PRODUCT});

  }

  ngOnInit(): void {
    this.user = this.authService.user;
    super.ngOnInit();
    this.getActiveCustomers().subscribe(success => {
      this.customerList = success;
    })
    this.operation = SERVICE_TYPES.COMPRA;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.user.user_obj.is_staff) {
      this.filteredOptions = this.formGroup.controls.customer.valueChanges
        .pipe(
          startWith(''),
          map(value => value ? this._filter(value) : this.customerList.slice())
        );
    }else {
      this.getData();
    }
  }

  public changeType(value) {
    this.type = value
    this.getData();
  }

  public clearField(formControl) {
    formControl.setValue('')
  }

  private getDatesFromCache() {
    if (localStorage.getItem("dtInicial") !== null) {
      this.dtInicial = moment(new Date(localStorage.getItem('dtInicial')), "DD/MM/YYYY")
    }
    if (localStorage.getItem("dtFinal") !== null) {
      this.dtFinal = moment(new Date(localStorage.getItem('dtFinal')), "DD/MM/YYYY")
    }
  }

  public getData(): void {
    this.service.clearParameter();
    this.service.addParameter('by_weight', this.type === 'K')

    this.getDatesFromCache()
    
    if (this.user.user_obj.is_staff) {
      this.service.addParameter('op_type', this.operation);
    }
    if (!this.user.user_obj.is_staff) {
      this.service.addParameter('customer', this.user.user_obj.users[0]);
    } else if (this.formGroup.get('customer').value) {
      this.service.addParameter('customer', this.formGroup.get('customer').value.id);
    } else {
      this.toast.error('Produtos por Cliente/fornecedor', 'Selecione o cliente');
      return;
    }
    if (this.dtInicial) {
      this.service.addParameter('dt_inicial', Utils.getDateToStringRequest(this.dtInicial._d));
    }
    if (this.dtFinal) {
      this.service.addParameter('dt_final', Utils.getDateToStringRequest(this.dtFinal._d));
    }
    this.service.getFromListRoute<ResiduesPerCustomerChart[]>('residues_client')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.createChart(response);
      });
  }

  public createChart(response: ResiduesPerCustomerChart[]): void {
    const dataAxis = [];
    const dataSeries = [];
    this.sumAll = 0;
    response.forEach(t => {
      dataAxis.push(t.residue__group__name.toUpperCase());
      dataSeries.push(t.total);
      this.sumAll += t.total;
    });

    let unit = this.type === 'K' ? ' Kg' : ' R$';

    this.weightOption = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: function (params, ticket, callback) {
          return params[0].value.toLocaleString('pt') + unit;
        }
      },
      toolbox: {
        feature: {
          saveAsImage: {
            show: true,
            title: 'download'
          },
        },
        top: 'top',
        right: 15,
        orient: 'vertical',
        itemGap: 20
      },
      xAxis: {
        type: 'category',
        data: dataAxis,
      },
      yAxis: {
        type: 'value',
        splitArea: {
          show: true,
          areaStyle: {
            color: ['#f8f8f8', '#c7c3c3']
          }
        }
      },
      series: [{
        type: 'bar',
        data: dataSeries,
      }],
      // color: [this.color],Input
    };

    this.showChart = response.length > 0;
  }

  saveDate(name, value): void {
      localStorage.setItem(`${name}`, value.toDate())
  }

  createFormGroup(): void {
    if (!this.user.user_obj.is_staff) {
      this.formGroup = this.formBuilder.group({
        customer: [null, Validators.required],
      });
    } else {
      this.formGroup = this.formBuilder.group({
        customer: [],
      });
    }
  }

  getActiveCustomers() {
    this.customerService.clearParameter();
    this.customerService.addParameter('active', true);
    return this.customerService.getAll();
  }

  displayFn(customer?: Customer): string | undefined {
    return customer ? customer.name : undefined;
  }

  private _filter(name: string): Customer[] {
    let filterValue = name;
    if (typeof name === 'string') {
      filterValue = name.toLowerCase();
    }
    return this.customerList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
}
