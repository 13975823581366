export const URLS = {
  // auth
   TOKEN: '/token/',
   JWT_TOKEN: '/api-token-auth/',
  USER: '/user/',
  GROUP: '/group/',
  PERMISSION: '/permission/',

  TICKETS: '/ticket/',
  CUSTOMER: '/customer/',
  RESIDUE: '/residue/',
  TRUCKS: '/truck/',
  TABLE_PRICE: '/table_price/',
  TICKET_PRODUCT: '/ticket_residue/',
  GROUP_TYPE: '/group_type/',
  LICENSE: '/license/',
  CERTIFICATE: '/certificate/',
  UPLOAD: "/file_repository/",
  CUSTOMER_VIEW_LICENSE: "/customer_view_license/"


};
