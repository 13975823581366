<div fxLayout="column" fxLayoutGap="20px">
    <div>
        <button mat-button color="primary" [routerLink]="['/product-list']">
            <mat-icon class="ic-back">chevron_left</mat-icon>
            VOLTAR
        </button>
    </div>

    <form [formGroup]="formGroup" novalidate (ngSubmit)="saveOrUpdateFormData()">
        <mat-card>
            <mat-card-content fxLayout="row" fxLayoutGap="20px">
                <mat-form-field fxFlex>
                    <mat-select placeholder="Grupo" formControlName="group">
                        <mat-option *ngFor="let group of groupList" [value]="group.id">
                            {{ group.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formGroup.controls['group'].hasError('required')">
                        Grupo é <strong>obrigatório</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex>
                    <input id="cnpj" matInput placeholder="Produto" formControlName="name">
                    <mat-error *ngIf="formGroup.controls['name'].hasError('required')">
                        Produto é <strong>obrigatório</strong>
                    </mat-error>
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions align="end">
                <button mat-raised-button color="primary" [routerLink]="['/product-list']">Cancelar</button>
                <button [disabled]="formGroup.invalid" mat-raised-button color="accent" type="submit">Salvar</button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>
