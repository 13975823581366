import {AfterViewInit, Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from "../base.component";
import {URLS} from "../app.urls";
import {Customer} from "../models/models.model";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {AuthService} from "../services/auth.service";
import {CustomerService} from "../services/services.service";
import {MatDatepicker} from '@angular/material/datepicker';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {Validators} from '@angular/forms';
import * as pdfmake from 'pdfmake/build/pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ToastService } from '../services/toast.service';
import { formatNumber } from '@angular/common';
import {FULL_MONTHS, MONTHS, PDF_BACKGROUND, PDF_LOGO} from "../app.constant";
pdfmake.vfs = pdfFonts.pdfMake.vfs;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMMM/YYYY', // this is the format showing on the input element
    monthYearLabel: 'MMMM/YYYY', // this is showing on the calendar
  },
};

@Component({
  selector: 'app-destination-cert',
  templateUrl: './destination-cert.component.html',
  styleUrls: ['./destination-cert.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class DestinationCertComponent extends BaseComponent<any> implements OnInit, AfterViewInit {

  displayedColumns =  ['period', 'total', 'action'];
  public filteredOptions: Observable<Customer[]>;
  public user: any;
  public customerList = new Array<Customer>();
  public displayedCertificate: any;
  public months = MONTHS;
  public fullmonths = FULL_MONTHS;

  constructor(
      public injector: Injector,
      private authService: AuthService,
      private customerService: CustomerService,
      public toast: ToastService,
  ) {
    super(injector, {
      endpoint: URLS.TICKET_PRODUCT,
      pagination: true,
      searchOnInit: false,
      keepFilters: true,
      retrieveOnInit: false,
      title: 'Certificade Destinação',
    });
  }

  ngOnInit(callback?: () => void) {
    this.user = this.authService.user;
    if (this.user.is_staff) {
      this.displayedColumns = ['period', 'total', 'recipient', 'action'];
    }
    this.displayedCertificate = {
      costumer: '',
      residue: '',
      quantity: 0
    }
    super.ngOnInit(callback);
    this.getActiveCustomers().subscribe(success => {
      this.customerList = success;
    })
    setTimeout(() => {
      this.isClient();
    });
  }

  private isClient() {
    const user: any = this.authService.user;
    if (user.user_obj.users.length != 0) {
      this.formGroup.get('customer').setValue(user.user_obj.users[0]);
      this.formGroup.get('customer').disable();
    }
    this.service.addParameters(this.formGroup);
    this.searchButton();
  }

  getActiveCustomers() {
    this.customerService.clearParameter();
    this.customerService.addParameter('active', true);
    return this.customerService.getAll();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.user.user_obj.users) {
      this.filteredOptions = this.formGroup.controls.customer.valueChanges
          .pipe(
              startWith(''),
              map(value => value ? this._filter(value) : this.customerList.slice())
          );
    }
  }

  private _filter(name: string): Customer[] {
    let filterValue = name;
    if (typeof name === 'string') {
      filterValue = name.toLowerCase();
    }
    return this.customerList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }


  createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      customer: [null, Validators.required],
      dt_inicial: [null],
      dt_final: [null, Validators.required],
    });
  }

  public searchButton(): void {
    this.service.clearParameter();
    this.service.addParameters(this.formGroup);
    if (!this.user.is_staff) {
      this.service.addParameter('customer', this.formGroup.controls.customer.value);
    }
    this.service.getFromListRoute<any>('destination_cert_list').subscribe(result => {
      this.dataSource.data = result;
    })
  }

  public clearField(formControl) {
    formControl.setValue('')
  }

  displayFn(customer?: Customer): string | undefined {
    return customer ? customer.name : undefined;
  }

  chosenMonthHandler(d: Moment, datepicker: MatDatepicker<Date>) {
    const curentPeriod = moment()
    const startOfMonth = d.clone().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = d.clone().endOf('month').format('YYYY-MM-DD');

    if (d.clone().endOf('month').diff(curentPeriod) >= 0) {
      this.toast.error("Período ainda não fechado", "Data inválida");
    } else {
      this.formGroup.get('dt_inicial').setValue(startOfMonth)
      this.formGroup.get('dt_final').setValue(endOfMonth)
    }

    datepicker.close();
  }

  displayPeriod(d): string {
    return this.fullmonths[d - 1];
    // return moment(d).locale('pt_br').format("MMMM/YYYY");
  }

  createTableBody(result) {
    var body = [];
    body.push(
        [
          {text: 'RESÍDUO', style: "header"},
          {text: 'PESAGEM TOTAL', style: "header"},
          {text: 'DESTINO', style: "header"},
          {text: 'TRATAMENTO', style: "header"}
        ]);
    result.items.forEach(item => {
      const recipient = (item.ticket__recipient__name ? item.ticket__recipient__name.split(' ')[0] : "");
      const residue =
          [
            {text: `${item.residue__group__name}`, style: "body"},
            {text: `${formatNumber(item.total, 'pt_br')} Kg`, style: "body"},
            {text: `${recipient}`, style: "body"},
            {text: `${item.residue__group__treatment}`, style: "body"},
          ];
      body.push(residue);
    });
    return body;
  }

  exportAsPDF(row) {
    this.service.clearParameter();
    this.service.addParameter('customer', row.ticket__customer_id)
    this.service.addParameter('month', row.month)
    this.service.addParameter('year', row.year)
    this.service.getFromListRoute<any>('destination_cert').subscribe(result => {
      const documentDefinition =
          {
            background: {
              image: PDF_BACKGROUND,
              width: 615
            },
            content: [
              {
                image: PDF_LOGO,
                width: 200
              },
              {
                text: "CERTIFICADO DE DESTINAÇÃO", color: '#7E7E7E', fontSize: 20, margin: [0, 15, 0, 15], bold: true
              },
              { text: `Nº ${row.month < 10  ? '0'+ row.month : row.month}/${row.year}

          `, color: 'gray', fontSize: 14},
              {
                columns: [
                  {
                    witdh: '*',
                    text: ''
                  },
                  {
                    width: 420,
                    text: [
                      {
                        text: ` A EMPRESA LB Ambiental CNPJ.: 18.098.369/0001-44, sediada a Av. Rodrigo,
                    Otávio, 1279 – Bairro - Crespo, certifica que no mês de ${this.displayPeriod(row.month).toUpperCase()}/${row.year}
                    destinou da empresa ${result.customer_name.toUpperCase()}
                    CNPJ: ${result.customer_cnpj.toUpperCase()} os resíduos conforme descrição abaixo:` ,
                        color: '#585858',
                        fontSize: 12,
                        bold: true,
                        lineHeight: 2,
                        alignment: 'justify'
                      },
                    ]
                  },
                  {
                    witdh: '*',
                    text: ''
                  }
                ]
              },
              {
                table: {
                  headerRows: 1,
                  widths: ['*', '*', '*', '*'],
                  body: this.createTableBody(result)
                },
                layout: {
                  hLineColor: 'white',
                  vLineColor: 'white'
                }
              },
            ],
            pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
              return currentNode.startPosition.top >= 650;
            },
            styles: {
              header: {
                fontSize: 11,
                bold: true,
                fillColor: '#9bba58',
                color: '#fff',
                margin: [5, 5, 5, 5]
              },
              body: {
                fontSize: 11,
                bold: false,
                fillColor: '#edebe0',
                color: '#585858',
                margin: [5, 5, 5, 5]
              },
              text: {
                color: '#585858',
                fontSize: 12,
                alignment: 'center',
                margin: [0, 10, 0, 0],
                lineHeight: 2,
              }
            },
            defaultStyle: {
              alignment: 'center'
            }
          };
      pdfmake.createPdf(documentDefinition).download(`${row.ticket__customer__name}_${this.displayPeriod(row.month).toUpperCase()}.pdf`);
    })
  }
}
