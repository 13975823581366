<div fxLayout="column" fxLayoutGap="20px">
    <div>
        <button mat-button color="primary" [routerLink]="['/client-list']">
            <mat-icon class="ic-back">chevron_left</mat-icon>
            VOLTAR
        </button>
    </div>

    <form [formGroup]="formGroup" novalidate (ngSubmit)="saveOrUpdateFormData()">
        <div fxLayout="column" fxLayoutGap="20px">
            <mat-card>
                <mat-card-content>
                    <div class="row">
                        <mat-form-field fxFlex>
                            <input id="cliente" matInput placeholder="Nome" formControlName="name">
                            <mat-error *ngIf="formGroup.controls['name'].hasError('required')">
                                First name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="row" fxLayoutGap="20px">
                        <mat-form-field fxFlex>
                            <input id="cnpj" matInput placeholder="CNPJ" formControlName="cnpj">
                            <mat-error *ngIf="formGroup.controls['cnpj'].hasError('required')">
                                CNPJ é <strong>obrigatório</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex>
                            <input id="estadual" matInput placeholder="Inscrição estadual"
                                   formControlName="state_registration">
                            <mat-error *ngIf="formGroup.controls['state_registration'].hasError('required')">
                                Inscrição municipal é<strong>obrigatório</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field fxFlex>
                            <textarea id="endereco" matInput placeholder="Endereço"
                                      formControlName="address"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="row" fxLayoutGap="20px">
                        <mat-form-field fxFlex>
                            <input matInput placeholder="Inscrição municipal"
                                   formControlName="municipal_registration">
                        </mat-form-field>
                        <mat-form-field fxFlex>
                            <input id="phone" matInput placeholder="Phone" formControlName="phone">
                        </mat-form-field>
                    </div>
                </mat-card-content>
                <mat-card-actions align="end">
                    <button mat-raised-button color="primary" [routerLink]="['/client-list']">Cancelar</button>
                    <button [disabled]="formGroup.invalid" mat-raised-button color="accent" type="submit">Salvar
                    </button>
                </mat-card-actions>
            </mat-card>

            <mat-card>
                <mat-tab-group>
                    <mat-tab label="Tabela de Preço">
                        <ng-template matTabContent>
                            <app-table-price></app-table-price>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Licenças">
                        <ng-template matTabContent>
                            <app-client-license></app-client-license>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Visualizar licença">
                        <ng-template matTabContent>
                            <app-client-provider></app-client-provider>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Caminhões">
                        <ng-template matTabContent>
                            <app-client-truck></app-client-truck>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Usuários">
                        <ng-template matTabContent>
                            <app-client-user></app-client-user>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </mat-card>
        </div>
    </form>

</div>
