import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { MESSAGES } from 'src/app/app.constant';
import { EVENT } from 'src/app/base.component';
import { ToastService } from 'src/app/services/toast.service';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { TitlePageService } from 'src/app/shared/title-page.service';
import { ClientLicense, Customer } from "../../models/models.model";
import { CustomerService, CustomerViewLicenseService } from "../../services/services.service";

@Component({
  selector: 'app-client-provider',
  templateUrl: './client-provider.component.html',
  styleUrls: ['./client-provider.component.scss']
})
export class ClientProviderComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public dataSource = new MatTableDataSource<any>()
  public formGroup: FormGroup;
  public object = {};
  public clientViewLicensesList = new Array<Customer>();
  public customerList = new Array<Customer>();
  public providerList = new Array<Customer>();
  public filteredOptions: Observable<Customer[]>;
  private unsubscribe = new Subject();
  public id_client: string;

  displayedColumns = ['name', 'action'];

  constructor(
      public formBuilder: FormBuilder,
      private customerService: CustomerService,
      private clientViewLicenseService: CustomerViewLicenseService,
      private activatedRoute: ActivatedRoute,
      public toast: ToastService,
      private title: TitlePageService,
      private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.createFormGroup();
    this.getCustomers().subscribe(success => {
      this.customerList = success;
    });
    if (this.activatedRoute.snapshot.params["action"] !== "new") {
      this.id_client = this.activatedRoute.snapshot.params["action"];
      if (this.id_client === 'default') {
        this.id_client = null;
      }
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.filteredOptions = this.formGroup.controls.provider.valueChanges
        .pipe(
            startWith(''),
            map(value => value ? this._filter(value) : this.customerList.slice())
        );


    this.getClientViewLicenses();
  }

  createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      customer: [null],
      provider: [null,Validators.required],
    });
  }

  private _filter(name: string): Customer[] {
    let filterValue = name;
    if (typeof name === 'string') {
      filterValue = name.toLowerCase();
    }
    return this.customerList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  getCustomers() {
    this.customerService.clearParameter();
    this.customerService.addParameter('active', true);
    return this.customerService.getAll();
  }

  getClientViewLicenses() {
    this.clientViewLicenseService.clearParameter();
    this.clientViewLicenseService.addParameter('customer', this.id_client);
    this.clientViewLicenseService.getAll().subscribe(success => this.providerList = success);
  }


  saveClientProvider() {
    this.clientViewLicenseService.clearParameter();
    this.formGroup.controls.customer.setValue(this.id_client);
    this.object = this.formGroup.getRawValue();
    const formData = new FormData();
    Object.keys(this.object).forEach(key => {
      const value = this.object[key];
      if (value !== null && typeof value === 'object') {
        formData.append(key == "provider"? "customer_view" : key, value === undefined ? '' : value.id);
      } else {
        formData.append(key, value === null || value === undefined ? '' : value);
      }
    });
    this.save(formData);
  }

  private save(aObject): void {
    this.clientViewLicenseService.save(aObject)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
            response => {
              this.toast.success(MESSAGES.success_title, MESSAGES.saved_successfully);
              this.formGroup.reset({});
              this.getClientViewLicenses();
            }
        );
  }

  public clearField(formControl) {
    formControl.setValue('')
  }

  displayFn(provider?: Customer): string | undefined {
    return provider ? provider.name : undefined;
  }

  public delete(pk: any, description: string, callback?: (event) => void): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '275px',
      // height: '220px',
      data: {
        id: pk,
        title: 'Apagar',
        message: 'Deletar ',
        description: description
      }
    });

    dialogRef.afterClosed()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(result => {
          if (result) {
            this.clientViewLicenseService.delete(pk)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                      this.toast.success(MESSAGES.success_title, MESSAGES.deleted_successfully);
                      this.getClientViewLicenses();
                      if (callback) {
                        callback(EVENT.DELETE);
                      }
                    }
                );
          }
        });
  }
}
