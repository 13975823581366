<mat-card>
    <mat-card-header fxLayout="start center">
        <mat-card-title class="title" fxLayoutAlign="space-between center">
            <div>Produtos por cliente/fornecedor</div>

            <mat-button-toggle-group #typebuy="matButtonToggleGroup"
                                     *ngIf="user.user_obj.is_staff"
                                     class="mat-elevation-z2" [value]="type" (change)="changeType(typebuy.value)">
                <mat-button-toggle class="toggle-bottom" value="K">Kg
                </mat-button-toggle>
                <mat-button-toggle class="toggle-top" value="R">R$
                </mat-button-toggle>
            </mat-button-toggle-group>
        </mat-card-title>
    </mat-card-header>

    <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex *ngIf="user.user_obj.is_staff">
            <mat-select [(ngModel)]="operation" name="op" (ngModelChange)="getData()">
                <mat-option value="C"> Compra</mat-option>
                <mat-option value="V"> Venda</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex [formGroup]="formGroup" *ngIf="user.user_obj.is_staff">
            <input #clientInput matInput type="text" placeholder="Cliente" formControlName="customer" [matAutocomplete]="auto">
            <button  mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearField(formGroup.controls['customer'])">
                <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field fxFlex>
            <mat-label>Data inicial</mat-label>
            <input id="rcdtinicial" matInput [matDatepicker]="picker" [(ngModel)]="dtInicial" (dateChange)="saveDate('dtInicial', dtInicial)">
            <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
            <mat-datepicker #picker [startAt]="dtInicial"></mat-datepicker>
        </mat-form-field>
        <mat-form-field fxFlex>
            <mat-label>Data final</mat-label>
            <input id="rcdtfinal" matInput [matDatepicker]="picker2" [(ngModel)]="dtFinal" (dateChange)="saveDate('dtFinal', dtFinal)">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 [startAt]="dtFinal"></mat-datepicker>
        </mat-form-field>
        <button class="bt-search" mat-icon-button color="primary" type="button" (click)="getData()">
            <mat-icon>search</mat-icon>
        </button>
    </div>
    <div fxLayout="row">
        <h4>Total: &nbsp;</h4>
        <h3><span>{{ type != 'K' ? "R$" : ""}}</span> {{sumAll | number: '1.0-0'}} <span>{{ type == 'K' ? "Kg" : ""}}</span></h3>
    </div>
    <div fxLayout="row" *ngIf="showChart">
        <div fxLayout="column" class="border" fxFlex [theme]="theme" echarts [options]="weightOption"></div>
    </div>
</mat-card>
