<button mat-fab id="add" [routerLink]="['new']">
    <i class="material-icons">add</i>
</button>

<div fxLayout="column" fxLayoutGap="15px">

    <form [formGroup]="formGroup" (ngSubmit)="search(paginator, sort)">
        <div fxLayout="row">
            <mat-form-field>
                <input name="name" matInput placeholder="Pesquisar" type="text" maxlength="32"
                       formControlName="customer">
            </mat-form-field>
            <button mat-icon-button type="submit" color="primary" class="bt-search">
                <i class="material-icons">search</i>
            </button>
        </div>
    </form>

    <div class="mat-elevation-z1">
        <table mat-table class="full-width-table" matSort aria-label="Elements">
            <!-- phone Column -->
            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
                <td mat-cell *matCellDef="let row">{{row.phone}}</td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let row"><a [routerLink]="[row.id]">{{row.name}}</a></td>
            </ng-container>

            <!-- cnpj Column -->
            <ng-container matColumnDef="cnpj">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>CNPJ</th>
                <td mat-cell *matCellDef="let row">{{row.cnpj}}</td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let element" align="end">
                    <button mat-button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="delete(element.id, element.name)">
                            <mat-icon>delete</mat-icon>
                            <span>Remover</span>
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator #paginator [length]="paginator.length" [pageIndex]="paginator.pageIndex"
                       [pageSize]="paginator.pageSize" [pageSizeOptions]="paginator.pageSizeOptions"
                       (page)="search($event, sort)">
        </mat-paginator>
    </div>

</div>
