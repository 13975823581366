import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public formGroup: FormGroup;
  public url: string;
  public message: string;

  constructor(
    public authService: AuthService,
    public formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public router: Router) {
  }

  public ngOnInit() {
    this.createFormGroup();

    // default message
    this.message = "Entrar";

    // get return url from route parameters or default to '/'
    this.url = this.route.snapshot.queryParams["u"] || "/";
  }

  public createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  public login(): void {
    this.message = "Processando";
    this.authService.login(this.formGroup.value)
      .subscribe(
        () => this.router.navigate([this.url]),
        () => {
          this.message = "Carregando...";
          this.formGroup.controls.password.reset();
        });
  }
}
