import {SERVICE_TYPES, TRUCK_TYPES} from '../app.constant';
import { User } from './user';

export class ModelBase {
  id: number;
  url: string;
  active: boolean;
  // tslint:disable-next-line: variable-name
  created_at: Date;
  // tslint:disable-next-line: variable-name
  modified_at: Date;
}

export class Customer extends ModelBase {
  public name: string;
  address: string;
  phone: string;
  cnpj: string;
  // tslint:disable-next-line: variable-name
  municipal_registration: string;
  // tslint:disable-next-line: variable-name
  state_registration: string;
  users: Array<User>;
}

export class GroupType extends ModelBase {
  public name: string;
}

export class Residue extends ModelBase {
  group: GroupType;
  name: string;
}

export class ResiduesQuantity {
  ticket: Ticket;
  residue: Residue;
  weight: number;
  value: number;
  date_weight: Date;
  operator: string;
}

export class Truck extends ModelBase {
  plate: string;
  model: string;
  type: TRUCK_TYPES;
}

export class Ticket extends ModelBase {
  customer: Customer;
  ticket_number: string;
  weight: number;
  truck: Truck;
  no_packing_list: string;
  service_type: SERVICE_TYPES;
  residues: Residue;
  comments: string;
  value: number;
  products_final: Array<ResiduesQuantity>;
  customer_obj: Customer;
  recipient_obj: Customer;
  is_service: boolean;
}

export class TablePrice {
  residue: string;
  id: number;
  price: number;
  price_sell: number;
  customer: number;
  residue_name: string;
}

export class License extends ModelBase {
  name: string;
  validity_days: number;
}

export class ClientLicense extends ModelBase {
  customer: Customer;
  license: License;
  date_start: string;
}

