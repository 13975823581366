import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class ToastService {

    constructor(public toast: ToastrService) {
    }

    public success(title: string, content: string): void {
        this.toast.success((title), content);
    }

    public error(title: string, content: string): void {
        this.toast.error((title), content);
    }

    public warning(title: string, content: string): void {
        this.toast.warning((title), content);
    }

    public info(title: string, content: string): void {
        this.toast.info((title), content);
    }
}
