import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {FormBuilder, FormGroup} from "@angular/forms";
import { LicenseService, TrucksService} from "../../services/services.service";
import {License} from "../../models/models.model";
import {Observable, Subject} from "rxjs";
import {map, startWith, takeUntil} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {MESSAGES, TRUCK_TYPES} from "../../app.constant";
import {ToastService} from "../../services/toast.service";
import {TitlePageService} from "../../shared/title-page.service";
import {DialogComponent} from "../../shared/dialog/dialog.component";
import {EVENT} from "../../base.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-client-truck',
  templateUrl: './client-truck.component.html',
  styleUrls: ['./client-truck.component.scss']
})
export class ClientTruckComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public dataSource = new MatTableDataSource<any>()
  public formGroup: FormGroup;
  public object = {};
  public truckList = new Array<License>();
  public filteredLicenses: Observable<License[]>;
  public id_client: string;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name', 'expire', 'action'];
  private unsubscribe = new Subject();

  constructor(
      public formBuilder: FormBuilder,
      private licenseService: LicenseService,
      private truckService: TrucksService,
      private activatedRoute: ActivatedRoute,
      public toast: ToastService,
      private title: TitlePageService,
      private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.createFormGroup();
    if (this.activatedRoute.snapshot.params["action"] !== "new") {
      this.id_client = this.activatedRoute.snapshot.params["action"];
      if (this.id_client === 'default') {
        this.id_client = null;
      }
    }

  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getCustomerTrucks();
  }

  public createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [],
      customer: [],
      plate: [],
      model: [],
      type: [],
    });
  }

  saveClientTruck() {
    this.truckService.clearParameter();
    this.formGroup.controls.customer.setValue(this.id_client);
    this.object = this.formGroup.getRawValue();
    const formData = new FormData();
    Object.keys(this.object).forEach(key => {
      const value = this.object[key];
      if (value !== null && typeof value === 'object') {
        formData.append(key, value === undefined ? '' : value.id);
      } else {
        formData.append(key, value === null || value === undefined ? '' : value);
      }
    });
    if (this.object['id']) {
      this.truckService.update(this.object['id'], formData).subscribe(success => {
        this.getCustomerTrucks();
      });
    } else {
      this.save(formData);
    }
  }


  private save(aObject): void {
    this.truckService.save(aObject)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
            response => {
              this.toast.success(MESSAGES.success_title, MESSAGES.saved_successfully);
              this.getCustomerTrucks();
            }
        );
  }

  getCustomerTrucks() {
    this.truckService.clearParameter();
    if (this.id_client){
      this.truckService.addParameter('customer', this.id_client.toString());
    }else {
      this.truckService.addParameter('customer', null);
    }
    this.truckService.getAll().subscribe(success => {
      this.dataSource.data = success;
    })
  }

  displayFnR(license?: License): string | undefined {
    return license ? license.name : undefined;
  }

  public delete(pk: any, description: string, callback?: (event) => void): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '275px',
      // height: '220px',
      data: {
        id: pk,
        title: 'Apagar',
        message: 'Deletar ',
        description: description
      }
    });

    dialogRef.afterClosed()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(result => {
          if (result) {
            this.truckService.delete(pk)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                      this.toast.success(MESSAGES.success_title, MESSAGES.deleted_successfully);
                      this.getCustomerTrucks();
                      if (callback) {
                        callback(EVENT.DELETE);
                      }
                    }
                );
          }
        });
  }

}
