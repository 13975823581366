import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from "../../base.component";
import {WeightGroupChart} from "../../dtos/weight-group-chart";
import {URLS} from "../../app.urls";
import {takeUntil} from "rxjs/operators";
import {MatButtonToggleGroup} from "@angular/material/button-toggle";
import EChartOption = echarts.EChartOption;
import {Utils} from "../../shared/utils";
import {AuthService} from "../../services/auth.service";


@Component({
  selector: 'app-total-weight-sale',
  templateUrl: './total-weight-sale.component.html',
  styleUrls: ['./total-weight-sale.component.scss']
})
export class TotalWeightSaleComponent extends BaseComponent<WeightGroupChart> implements OnInit {

  public showChart = false;
  public weightOption: EChartOption;
  public theme = 'macarons';
  public dtInicial: any;
  public dtFinal: any;
  public type = 'K';
  public user: any;

  // R= valor / K = peso
  @ViewChild("typesell", {static: false}) public typebuy: MatButtonToggleGroup;

  constructor(public injector: Injector, private authService: AuthService) {
    super(injector, {endpoint: URLS.TICKETS});
  }

  ngOnInit() {
    this.user = this.authService.user;
    super.ngOnInit();
    this.getData();
  }

  public getData(): void {
    this.service.clearParameter();
    if (this.user.user_obj.users.length){
      this.service.addParameter('customer', this.user.user_obj.users[0]);
    }
    this.service.addParameter('by_weight', this.type==='K')
    if (this.dtInicial) {
      this.service.addParameter('dt_inicial', Utils.getDateToStringRequest(this.dtInicial._d));
    }
    if (this.dtFinal) {
      this.service.addParameter('dt_final', Utils.getDateToStringRequest(this.dtFinal._d));
    }
    this.service.getFromListRoute<WeightGroupChart[]>("find_report_sale")
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(response => {
          this.createChart(response);
        });
  }

  public changeType(value) {
    this.type = value
    this.getData();
  }

  public createChart(response: WeightGroupChart[]): void {
    if (!(response && response.length > 0)) {
      return;
    }

    const dataSeries = [];
    const dataLegend = [];

    response.forEach(t => {
      dataSeries.push({
        value: t.total,
        name: t.residue__group__name
      });
      dataLegend.push(t.residue__group__name);
    });
    let unit = this.type === 'K'? 'Kg' : 'R$';

    this.weightOption = {
      tooltip: {
        trigger: "item",
        formatter: function (params, ticket, callback) {
          return `${params["name"]}: ${unit}: ${params["value"].toLocaleString('pt')} (${ params["percent"]}%)`
        }
        // formatter: "{b}: {c}Kg ({d}%)"
      },
      legend: {
        orient: "vertical",
        left: "left",
        data: dataLegend
      },
      toolbox: {
        feature: {
          saveAsImage: {
            show: true,
            title: "download"
          },
        },
        top: "top",
        right: 20,
        orient: "vertical",
        itemGap: 20
      },
      series: [{
        name: "Nome",
        type: "pie",
        // radius: ["60%", "80%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: true,
            formatter: "{d}%",
            fontSize: 16,
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: "30",
              fontWeight: "bold"
            }
          }
        },
        labelLine: {}
        ,
        data: dataSeries
      }]
    };

    this.showChart = response.length > 0;
  }

  public createFormGroup(): void {
  }

}
