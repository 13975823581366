import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from "../../base.component";
import {Customer, GroupType} from "../../models/models.model";
import {FormBuilder, Validators} from "@angular/forms";
import {URLS} from "../../app.urls";
import {GroupTypeService} from "../../services/services.service";

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent extends BaseComponent<GroupType> implements OnInit{

  public object: Customer = new Customer();
  public groupList: GroupType[];

  constructor(
      private fb: FormBuilder,
      public injector: Injector,
      protected groupService: GroupTypeService,
  ) {
    super(
        injector, {
          pk: 'id',
          endpoint: URLS.RESIDUE,
          nextRoute: '/product-list',
          nextRouteUpdate: '/product-list',
          retrieveOnInit: true,
          title: 'Produtos'
        });
  }

  public ngOnInit(callback?: () => void) {
    super.ngOnInit(callback);
    this.getActiveGroups().subscribe(success => {
      this.groupList = success;
    })

  }

  public createFormGroup(): void {
    this.formGroup = this.fb.group({
      group: [null, Validators.required],
      name: [null, Validators.required],
      active: [true],
    });

  }

  getActiveGroups() {
    this.groupService.clearParameter();
    this.groupService.addParameter('active', true);
    return this.groupService.getAll();
  }

}
