import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ToastService} from '../services/toast.service';
import {tap} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {FullLoadService} from "../services/full-load.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private request_count = 0;

  constructor(public toast: ToastService,
              public authService: AuthService,
              private loadService: FullLoadService,) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.request_count++;
    if (this.request_count === 1) {
      this.loadService.show.next(true);
    }
    const headers = {'Accept-Language': 'pt-BR'};
    const request = req.clone({setHeaders: headers});

    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.handleLoad();
      }
    }, (errorResponse: HttpErrorResponse) => {
      console.log(errorResponse);
      this.handleLoad();
      this.handleError(errorResponse);
    }));
  }

  private handleError(err: HttpErrorResponse): void {
    console.log(err);
    const title = 'Erro';
    if (err.status === 0) {
      this.toast.error(title, 'unknown-error');
      return;
    } else if (err.status === 401) {
      this.authService.logout(true);
      return;
    }

    const errors = AuthInterceptor.captureError(err.error);
    errors.forEach(t => {
      if (t instanceof Blob) {
        const reader = new FileReader();
        reader.addEventListener('loadend', () => {
          this.showErrors(title, JSON.parse(reader.result.toString()));
        });
        reader.readAsText(t);
      } else {
        this.showErrors(title, t);
      }
    });
  }

  private showErrors(title, value: any): void {
    Object.keys(value).forEach((key: any) => {
      this.toast.error(title, value[key]);
    });
  }

  // Function to capture errors
  private static captureError(value: any): any[] {
    if (value instanceof Array) {
      return value;
    } else if (AuthInterceptor.isJson(value)) {
      return [value];
    }
    return [{detail: value}];
  }

  private static isJson(item) {
    item = typeof item !== 'string' ? JSON.stringify(item) : item;
    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }
    return typeof item === 'object' && item !== null;
  }

  private handleLoad(): void {
    this.request_count--;
    if (this.request_count === 0) {
      this.loadService.show.next(false);
    }
  }
}
