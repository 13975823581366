import {AfterViewInit, Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FileUploadService, ResidueService, TablePriceService, TrucksService} from '../../services/services.service';
import {Customer, Residue, Ticket, Truck} from '../../models/models.model';
import {URLS} from 'src/app/app.urls';
import {forkJoin, Observable} from 'rxjs';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {CustomerService} from 'src/app/services/services.service';
import {BaseComponent, EVENT} from 'src/app/base.component';
import {DialogComponent} from "../../shared/dialog/dialog.component";
import {MESSAGES} from "../../app.constant";

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent extends BaseComponent<Ticket> implements OnInit, OnDestroy, AfterViewInit {
  public customerList = new Array<Customer>();
  @ViewChild('fileInput') fileInput: ElementRef;

  public residueList = new Array<Residue>();
  public truckList = new Array<Truck>();
  public filteredOptions: Observable<Customer[]>;
  public filteredOptionsRecipient: Observable<Customer[]>;
  public filteredResidues: Observable<Residue[]>;
  public formGroup: FormGroup;
  public object: Ticket = new Ticket();
  public TRUCK_TYPES = {
    'R': 'ROL-ON',
    'M': 'MULCK',
    'P': 'POLIGUINCHO',
  };
  public date: any;
  public fileToUpload : any;
  public files = [];
  public displayedColumns: string[] = ['file', 'action'];

  hasUnitNumber = false;
  public pesoDiff: number;
  public valorTotal = 0;

  constructor(
      private fb: FormBuilder,
      private customerService: CustomerService,
      private residueService: ResidueService,
      private truckService: TrucksService,
      private tablePrice: TablePriceService,
      private uploadService: FileUploadService,
      public injector: Injector,
  ) {
    super(injector, {
      pk: 'id',
      endpoint: URLS.TICKETS,
      retrieveOnInit: true,
      title: 'Tickets'
    });
  }

  public ngOnInit() {

    this.loadAux();
  }

  ngAfterViewInit() {
    if (this.object.id){
      this.getTicketFiles();
    }
  }

  public createFormGroup(): void {
    this.formGroup = this.fb.group({
      customer: [null, Validators.required],
      ticket_number: [null],
      mtr: [null],
      recipient: [null],
      weight_final: [null],
      truck: [null, Validators.required],
      no_packing_list: null,
      service_type: [null, Validators.required],
      comments: [null],
      driver_name: [null],
      operator_initial: [null],
      date_weight_initial: [null],
      weight_initial: [null],
      operator_final: [null],
      date_weight_final: [null],
      active: [true],
      value: [0],
      residuo: [null],
      products_final: [null],
      is_service: [false],
    });

    this.formGroup.valueChanges.subscribe(value => {
      this.pesoDiff = value.weight_initial - value.weight_final;
    });

  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  postFile(fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append("ticket", this.object.id.toString());
    this.uploadService.save(formData).subscribe(
        value => {
          this.getTicketFiles();
          this.fileInput.nativeElement.value = "";
          this.fileToUpload = null;
        });
  }

  getTicketFiles(){
    this.uploadService.clearParameter();
    this.uploadService.addParameter("ticket", this.object.id);
    this.uploadService.getAll().subscribe(value => {
      this.files = value;
    })
  }

  public deleteFile(file){
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '275px',
      data: {
        id: file.id,
        title: 'Apagar',
        message: 'Deletar ',
        description: file.filename
      }
    });

    dialogRef.afterClosed()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(result => {
          if (result) {
            this.uploadService.delete(file.id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                      this.toast.success(MESSAGES.success_title, MESSAGES.deleted_successfully);
                      this.getTicketFiles();

                    }
                );
          }
        });
  }

  displayFn(customer?: Customer): string | undefined {
    return customer ? customer.name : undefined;
  }

  private _filter(name: string): Customer[] {
    let filterValue = name;
    if (typeof name === 'string') {
      filterValue = name.toLowerCase();
    }
    return this.customerList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterResidue(name: string): Residue[] {
    let filterValue = name;
    if (typeof name === 'string') {
      filterValue = name.toLowerCase();
    }
    return this.residueList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  getActiveCustomers() {
    this.customerService.clearParameter();
    this.customerService.addParameter('active', true);
    return this.customerService.getAll();
  }

  getActiveResidues() {
    this.residueService.clearParameter();
    this.residueService.addParameter('active', true);
    return this.residueService.getAll();
  }

  getActiveTrucks() {
    this.truckService.clearParameter();
    this.truckService.addParameter('active', true);
    this.truckService.getAll().subscribe(trucks => {
      this.truckList = trucks;
    });
  }

  loadAux() {
    forkJoin(
        [this.getActiveCustomers(),
          this.getActiveResidues(),
        ]
    ).subscribe(([customers, residues]) => {
      this.customerList = customers;
      this.residueList = residues;
      this.getActiveTrucks();
      this.filteredOptions = this.formGroup.controls.customer.valueChanges
          .pipe(
              startWith(''),
              map(value => value ? this._filter(value) : this.customerList.slice())
          );
      this.filteredOptionsRecipient = this.formGroup.controls.recipient.valueChanges
          .pipe(
              startWith(''),
              map(value => value ? this._filter(value) : this.customerList.slice())
          );
      this.filteredResidues = this.formGroup.controls.residuo.valueChanges
          .pipe(
              startWith(''),
              map(value => value ? this._filterResidue(value) : this.residueList.slice())
          );
    });
    super.ngOnInit(() => {
      this.formGroup.patchValue(
          {
            customer: this.object.customer_obj,
            recipient: this.object.recipient_obj
          });
    });
  }

  getResiduePrice(noClient?) {
    this.tablePrice.clearParameter();
    const customer = this.formGroup.get('customer').value;
    const residue = this.formGroup.get('residues').value;
    if (customer && !noClient) {
      this.tablePrice.addParameter('customer', customer.id);
    } else {
      this.tablePrice.addParameter('customer', null);
    }
    if (!Array.isArray(residue) && residue && typeof residue !== "string") {
      this.tablePrice.addParameter('residue', residue.id);
    }
    if (customer && !Array.isArray(residue) && residue != undefined && typeof residue !== "string" && typeof customer !== 'number') {
      this.tablePrice.getAll().subscribe(success => {
        if (success.length) {
          this.valorTotal = success[0].price * this.pesoDiff;
          this.formGroup.get('value').setValue(this.valorTotal | 0);
        } else if (!noClient) {
          noClient = !noClient;
          this.getResiduePrice(noClient);
        }

      });
    }
  }

  remove(item) {
    const index = this.formGroup.controls.residues.value.findIndex(x => x.id === item.id);
    this.formGroup.controls.residues.value.splice(index, 1);
  }

  onResponse(response: Ticket, event: number, callback?: (event) => void) {
    if (event == 2){
      if (this.fileToUpload){
        this.postFile(this.fileToUpload);
      }
    }
    this.object = response;
    this.formGroup.reset(response);
    this.formGroup.patchValue({customer: this.object.customer_obj, recipient: this.object.recipient_obj});
    this.getTicketFiles();
    this.goToPage("/ticket-list/" + this.object.id);
  }
}
