<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="example-toolbar main-header" fxLayoutAlign="space-between center">
    <!--
            <button
                    type="button"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    (click)="drawer.toggle()">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
    -->

    <div class="logo" fxLayoutAlign="center center">
      <img src="../../assets/images/gir_logo_header.svg" height="42px" alt="">
    </div>

    <div fxFlex class="title">{{titleName}}</div>

    <button mat-button color="primary">
      <!-- ALEXANDRO -->{{user?.username}}
      <mat-icon>account_circle</mat-icon>
    </button>

    <button mat-button mat-icon-button color="primary" matTooltip="Sair" (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
    </button>
  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container main-container"
                         [style.marginTop.px]="mobileQuery.matches ? 56 : 0"
                         (backdropClick)="close()">
    <mat-sidenav #drawer [fixedInViewport]="mobileQuery.matches" fixedTopGap="56"
                 [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="close()" disableClose
                 opened>
      <mat-nav-list>
        <a mat-list-item fxLayout="column" routerLinkActive="active" routerLink="/home">
          <mat-icon>insert_chart</mat-icon>
          <span>Dashboard</span>
        </a>
        <a *ngIf="user?.is_superuser" mat-list-item routerLinkActive="active" routerLink="/default-price/default">
          <mat-icon>monetization_on</mat-icon>
          <span>Tabela de preço</span>
        </a>
        <a *ngIf="user?.is_superuser" mat-list-item routerLinkActive="active" routerLink="/product-list">
          <mat-icon class="products"></mat-icon>
          <span>Produtos</span>
        </a>
        <a *ngIf="user?.is_superuser" mat-list-item routerLinkActive="active" routerLink="/client-list">
          <mat-icon class="clients"></mat-icon>
          <span>Clientes</span>
        </a>
        <a *ngIf="user?.is_superuser" mat-list-item routerLinkActive="active" routerLink="/my-license-list">
          <mat-icon class="my-licenses"></mat-icon>
          <span>Minhas Licenças</span>
        </a>
        <a mat-list-item routerLinkActive="active" routerLink="/ticket-list">
          <mat-icon>receipt</mat-icon>
          <span>Tickets</span>
        </a>
        <a mat-list-item routerLinkActive="active" routerLink="/certificate">
          <mat-icon svgIcon="certificate"></mat-icon>
          <span>Cert. Destinação</span>
        </a>
        <a mat-list-item routerLinkActive="active" routerLink="/license-list">
          <mat-icon class="licenses"></mat-icon>
          <span>Licenças Ambientais</span>
        </a>
        <a mat-list-item routerLinkActive="active" routerLink="/ticket_months">
          <mat-icon>list</mat-icon>
          <span>Tickets Mensais</span>
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content cdkScrollable fxLayout="column" fxLayoutAlign="start stretch">

      <!-- Add Content Here -->
      <div class="main-content" fxFlex>
        <router-outlet></router-outlet>
      </div>

      <mat-toolbar class="container-footer" fxFlex="80px">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
          <img src="../../assets/images/gir_logo_rodape.svg" height="32px" alt="">
          <div fxLayout="column">
            <span><strong>GERENCIAMENTO INTEGRADO DE RESÍDUOS</strong></span>
            <span>LB AMBIENTAL @ 2020. Todos os direitos reservados.</span>
          </div>
        </div>
      </mat-toolbar>

    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
