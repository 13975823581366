<div fxLayout="column" fxLayoutGap="20px">

    <div class="title" *ngIf="this.id_client">Fornecedores</div>

    <form novalidate [formGroup]="formGroup">
        <div fxLayout="row" fxLayoutGap="20px">

            <mat-form-field fxFlex [formGroup]="formGroup">
                <input #clientInput matInput type="text" placeholder="Empresa" formControlName="provider" [matAutocomplete]="auto">
                <button  mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearField(formGroup.controls['provider'])">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <div>
                <button mat-icon-button mat-raised-button color="accent" id="add_table" type="button"
                        (click)="saveClientProvider()">
                    <i class="material-icons">add</i>
                </button>
            </div>
        </div>
    </form>

    <div class="mat-elevation-z1">
        <table mat-table class="full-width-table" [dataSource]="providerList" matSort aria-label="Elements">

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fornecedor</th>
                <td mat-cell *matCellDef="let row">{{row.customer_view_obj.name}}</td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let element" align="end">
                    <button mat-icon-button color="accent" type="button" (click)="delete(element.id, element.customer_view_obj.name)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator #paginator
                       [length]="providerList?.length"
                       [pageIndex]="0"
                       [pageSize]="50"
                       [pageSizeOptions]="[25, 50, 100, 250]">
        </mat-paginator>
    </div>

</div>
