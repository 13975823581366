import {HttpClient, HttpHeaders, HttpParams, HttpUserEvent} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {from, Observable} from 'rxjs';
import {webSocket} from 'rxjs/webSocket';
import {environment} from '../../environments/environment';
import {throwError} from 'rxjs/internal/observable/throwError';
import {PaginatedResult} from '../dtos/paginated-result';
import {FormGroup} from "@angular/forms";
import {Utils} from "../shared/utils";

export class BaseService<T> {
  public urlBase: string;
  public urlSocket: string;
  public fullUrl: string;

  protected parameters: HttpParams = new HttpParams();

  constructor(public http: HttpClient,
              public path: string) {

    this.urlBase = environment.urlBase;
    this.urlSocket = environment.urlSocket;
    this.fullUrl = `${this.urlBase}${path}`;
  }

  public clearParameter(): void {
    this.parameters = new HttpParams();
  }

  public addParameter(key: string, value: any): void {
    this.parameters = this.parameters.append(key, value);
  }

  public addParameters(formGroup: FormGroup): void {
    Object.keys(formGroup.value).forEach(control => {
      if (control in formGroup.value && formGroup.value[control] !== "" && formGroup.value[control] !== null) {
        if (formGroup.value[control] instanceof Date) {
          this.parameters = this.parameters.append(control, Utils.getDateFormatToString(formGroup.value[control]));
        }
        if (formGroup.value[control] instanceof Object) {
          this.parameters = this.parameters.append(control, formGroup.value[control].id);
        } else {
          this.parameters = this.parameters.append(control, formGroup.value[control].toString());
        }
      }
    });
  }

  private getOptions(responseType?: any): any {
    const token = localStorage.getItem('tokenGir');
    const httpOptions = {};

    if (token) {
      httpOptions['headers'] = new HttpHeaders({'Authorization': 'Bearer '.concat(token)});
    }
    if (this.parameters) {
      httpOptions['params'] = this.parameters;
    }
    if (responseType) {
      httpOptions['responseType'] = responseType;
    }
    return httpOptions;
  }

  public getAll(): Observable<T[]> {
    return this.http.get<T[]>(this.fullUrl, this.getOptions())
        .pipe(
            tap(response => response as HttpUserEvent<T[]>),
            catchError(ex => from([]))
        );
  }

  public getPaginated(): Observable<PaginatedResult<T>> {
    return this.http.get<PaginatedResult<T>>(this.fullUrl, this.getOptions())
        .pipe(
            tap(response => response as HttpUserEvent<PaginatedResult<T>>),
            catchError(ex => from([]))
        );
  }


  public getPaginatedFromDetailRoute<K>(id: number, route: string): Observable<PaginatedResult<K>> {
    const url = `${this.fullUrl}${id}/${route}/`;
    return this.http.get<PaginatedResult<K>>(url, this.getOptions())
        .pipe(
            tap(response => response as HttpUserEvent<PaginatedResult<K>>),
            catchError(ex => from([]))
        );
  }

  public getPaginatedFromListRoute<K>(route: string): Observable<PaginatedResult<K>> {
    const url = `${this.fullUrl}${route}/`;
    return this.http.get<PaginatedResult<K>>(url, this.getOptions())
        .pipe(
            tap(response => response as HttpUserEvent<PaginatedResult<K>>),
            catchError(ex => from([]))
        );
  }

  public getFromDetailRoute<K>(id: number, route: string): Observable<K> {
    const url = `${this.fullUrl}${id}/${route}/`;
    return this.http.get<K>(url, this.getOptions())
        .pipe(
            tap(response => response as HttpUserEvent<K>),
            catchError(ex => from([]))
        );
  }

  public getFromListRoute<K>(route: string): Observable<K> {
    const url = `${this.fullUrl}${route}/`;
    return this.http.get<K>(url, this.getOptions())
        .pipe(
            tap(response => response as HttpUserEvent<K>),
            catchError(ex => from([]))
        );
  }

  public postFromDetailRoute<E, K>(id: number, route: string, entity: E): Observable<K> {
    const url = `${this.fullUrl}${id}/${route}/`;
    return this.http.post<K>(url, entity, this.getOptions())
        .pipe(
            tap(response => response as HttpUserEvent<K>),
            catchError(ex => from([]))
        );
  }

  public postFromListRoute<E, K>(route: string, entity: E): Observable<K> {
    const url = `${this.fullUrl}${route}/`;
    return this.http.post<K>(url, entity, this.getOptions())
        .pipe(
            tap(response => response as HttpUserEvent<K>),
            catchError(ex => from([]))
        );
  }

  public patchFromDetailRoute<E, K>(id: number, route: string, entity: E): Observable<K> {
    const url = `${this.fullUrl}${id}/${route}/`;
    return this.http.patch<K>(url, entity, this.getOptions())
        .pipe(
            tap(response => response as HttpUserEvent<K>),
            catchError(ex => from([]))
        );
  }

  public patchFromListRoute<E, K>(route: string, entity: E): Observable<K> {
    const url = `${this.fullUrl}${route}/`;
    return this.http.patch<K>(url, entity, this.getOptions())
        .pipe(
            tap(response => response as HttpUserEvent<K>),
            catchError(ex => from([]))
        );
  }

  public save(entity: T): Observable<T> {
    this.clearParameter();
    return this.http.post<T>(this.fullUrl, entity, this.getOptions())
        .pipe(
            tap(response => response as HttpUserEvent<T>),
            catchError(ex => from([]))
        );
  }

  public getById(id: number): Observable<T> {
    return this.http.get<T>(
        `${this.fullUrl}${id}/`, this.getOptions())
        .pipe(
            tap(response => response as HttpUserEvent<T>),
            catchError(ex => from([]))
        );
  }

  public delete(id: number | string): Observable<any> {
    this.clearParameter();
    const url = `${this.fullUrl}${id}/`
    return this.http.delete<any>(url, this.getOptions())
        .pipe(
            tap(response => response as HttpUserEvent<any>),
            catchError(ex => from([]))
        );
  }

  public update(id: number | string, entity: any): Observable<any> {
    this.clearParameter();
    const url = `${this.fullUrl}${id}/`
    return this.http.patch<T>(url, entity, this.getOptions())
        .pipe(
            tap(response => response as HttpUserEvent<T>),
            catchError(ex => throwError(ex))
        );
  }

  public options(): Observable<any> {
    return this.http.options<T>(this.fullUrl, this.getOptions())
        .pipe(
            tap(response => response as HttpUserEvent<any>),
            map(response => response['actions']['POST']),
            catchError(ex => from([]))
        );
  }

  public getChoices(field: string): Observable<any> {
    return this.http.options<any>(this.fullUrl, this.getOptions())
        .pipe(
            tap(response => response as HttpUserEvent<any>),
            map(response => response['actions']['POST'][field]['choices']),
            catchError(ex => from([]))
        );
  }

  public webSocketConnect<K>(path: string): Observable<K> {
    let url = `${this.urlSocket}/${path}/`;
    if (this.parameters.toString()) {
      url = `${url}?${this.parameters.toString()}`;
    }
    return webSocket<K>(url);
  }

  public loadUrl(url: string): Observable<T> {
    return this.http.get<T>(url, this.getOptions())
        .pipe(
            tap(response => response as HttpUserEvent<T>),
            catchError(ex => from([]))
        );
  }

  public generateFileReport(route: string, data: any): Observable<Blob> {
    const url = `${this.fullUrl}${route}/`;
    return this.http.post<Blob>(url, data, this.getOptions('blob'))
        .pipe(
            tap(response => response),
            catchError(ex => from([]))
        );
  }

}
