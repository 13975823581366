import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable()
export class FullLoadService {
    public show = new Subject();

    constructor() {
    }

}
