<div fxLayout="column" fxLayoutGap="20px">

    <div class="row" fxLayoutGap="20px">
        <div fxFlex class="highlight">
            <p>VALOR DO TICKET CALCULADO (R$)</p>
            <div class="line"></div>
            <h3>{{valorTotal | currency:'BRL'}}</h3>
        </div>
        <div fxFlex class="highlight">
            <p>Peso</p>
            <div class="line"></div>
            <h3>{{pesoTotal}} Kg</h3>
        </div>
    </div>

    <div fxLayout="column" fxLayoutGap="20px">

        <form [formGroup]="formGroup">
            <div fxLayout="row" fxLayoutGap="20px">
                <mat-form-field fxFlex>
                    <input matInput type="text" placeholder="Resíduo" formControlName="residue"
                           [matAutocomplete]="auto2">
                    <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFnR">
                        <mat-option *ngFor="let option of filteredResidues | async" [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field fxFlex>
                    <input id="peso_inicial" matInput type="number" formControlName="weight"
                           placeholder="Peso registrado (Kg)">
                </mat-form-field>
                <mat-checkbox *ngIf="ticket.is_service" formControlName="without_value">Sem valor financeiro
                </mat-checkbox>

                <!--        <label fxLayoutAlign="center center">{{valor| currency : 'BRL'}}</label>-->
                <button mat-icon-button mat-raised-button color="accent" type="button" (click)="addProduct()"
                        aria-label="Adicionar resíduo">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </form>

        <div class="mat-elevation-z1">
            <table mat-table class="full-width-table" matSort aria-label="Elements" fxFill>
                <ng-container matColumnDef="product">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Resíduo</th>
                    <td mat-cell *matCellDef="let row"><a [routerLink]="[row.id]"> {{row.residue_name}}</a></td>
                </ng-container>
                <!-- Id Column -->
                <ng-container matColumnDef="weight">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Peso</th>
                    <td mat-cell *matCellDef="let row">{{row.weight | number}}</td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="price_unit">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Valor Unitário</th>
                    <td mat-cell *matCellDef="let row">{{row.value_unit | currency:'BRL'}}</td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Valor</th>
                    <td mat-cell *matCellDef="let row">{{row.value | currency:'BRL'}}</td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell *matCellDef="let element" align="end">
                        <button mat-button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="delete(element.id, element.name)">
                                <mat-icon>delete</mat-icon>
                                <span>Remover</span>
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator #paginator [length]="paginator.length"
                           [pageSize]="paginator.pageSize" [pageSizeOptions]="paginator.pageSizeOptions"
                           (page)="search($event, sort)">
            </mat-paginator>
        </div>

    </div>

</div>
