<div fxLayout="column" fxLayoutGap="20px">

    <form [formGroup]="formGroup" (submit)="searchButton()">
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex [formGroup]="formGroup" *ngIf="user.user_obj.users.length == 0">
                <input #clientInput matInput type="text" placeholder="Cliente" formControlName="customer"
                       [matAutocomplete]="auto">
                <button mat-button matSuffix mat-icon-button aria-label="Clear"
                        (click)="clearField(formGroup.controls['customer'])">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-label>Data</mat-label>
                <input id="data_registro_final" dateFormat="YYYY-MM-DD" matInput [matDatepicker]="picker"
                       formControlName="dt_final">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker startView="multi-year"
                                (monthSelected)="chosenMonthHandler($event, picker)"></mat-datepicker>
            </mat-form-field>
            <div>
                <button mat-icon-button class="bt-search" type="submit" color="primary">
                    <i class="material-icons">search</i>
                </button>
            </div>
        </div>
    </form>

    <div class="mat-elevation-z1">
        <table mat-table class="full-width-table" matSort aria-label="Elements" [dataSource]="dataSource">
            <ng-container matColumnDef="customer">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Mês/Ano</th>
                <td mat-cell *matCellDef="let row">{{ row.ticket__customer__name}} </td>
            </ng-container>
            <ng-container matColumnDef="period">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Mês/Ano</th>
                <td mat-cell *matCellDef="let row">{{ displayPeriod(row.month) }}/{{row.year}} </td>
            </ng-container>
            <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Pesagem total</th>
                <td mat-cell *matCellDef="let row">{{row.total| number: '1.0-0'}} Kg</td>
            </ng-container>
            <!-- Admin view column -->
            <ng-container matColumnDef="recipient" *ngIf="user.user_obj.is_staff">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
                <td mat-cell *matCellDef="let row"> {{ row.ticket__recipient__name }}</td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Download</th>
                <td mat-cell *matCellDef="let row">
                    <mat-icon (click)="exportAsPDF(row);" style="cursor: pointer;">file_download</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <!--        {{paginator.pageSize}} - pageSize-->
        <mat-paginator #paginator [length]="paginator.length" [pageSize]="paginator.pageSize"
                       [pageSizeOptions]="paginator.pageSizeOptions" (page)="search($event, sort)">
        </mat-paginator>
    </div>
</div>
