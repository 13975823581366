<div class="container" fxLayout="column" fxLayoutGap="20px">
    <mat-card fxFlex *ngIf="user.is_superuser && dataSource.length > 0">
        <mat-card-header>
            <mat-card-title class="title">
                LICENÇAS EXPIRADAS
            </mat-card-title>
        </mat-card-header>
        <div fxLayout="row">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z1">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="client">
                    <th mat-header-cell *matHeaderCellDef fxFlex><h3>Cliente/Fornecedor</h3></th>
                    <td mat-cell *matCellDef="let element" fxFlex> {{element.client}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="license">
                    <th mat-header-cell *matHeaderCellDef fxFlex><h3>Licença</h3></th>
                    <td mat-cell *matCellDef="let element" fxFlex> {{element.license}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="expire_date">
                    <th mat-header-cell *matHeaderCellDef fxFlex><h3>Vencimento</h3></th>
                    <td mat-cell *matCellDef="let element" fxFlex> {{element.expire_date | date:'shortDate'}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card>

    <app-sale-residues-client></app-sale-residues-client>
    <app-weight-per-customer *ngIf="user.is_staff" fxFill></app-weight-per-customer>

    <div fxLayout="row"  *ngIf="user.is_staff" fxLayout.lt-lg="column" fxLayoutGap="20px">
        <app-total-weight-sale fxFlex></app-total-weight-sale>
        <app-total-weight fxFlex></app-total-weight>
    </div>

    <div fxLayout="column" fxLayoutGap="20px">
        <app-residues-per-client></app-residues-per-client>
        <app-residue-client-months></app-residue-client-months>
    </div>
</div>
<!--
<mat-grid-list cols="2">
  <mat-grid-tile colspan="2">
    <app-weight-per-customer class="altura-grafico" fxFill></app-weight-per-customer>
  </mat-grid-tile>
  <mat-grid-tile><app-total-weight-sale fxFill></app-total-weight-sale></mat-grid-tile>
  <mat-grid-tile><app-total-weight fxFill></app-total-weight></mat-grid-tile>
</mat-grid-list>
-->
