import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../base.component';
import {URLS} from '../../app.urls';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {CustomerService, GroupTypeService} from "../../services/services.service";
import {Utils} from "../../shared/utils";
import {MatButtonToggleGroup} from "@angular/material/button-toggle";
import {AuthService} from "../../services/auth.service";
import {ResiduesClient} from "../../dtos/residues_client";
import EChartOption = echarts.EChartOption;
import {Validators} from "@angular/forms";
import {Customer} from "../../models/models.model";
import {Observable} from "rxjs";
import {SERVICE_TYPES} from "../../app.constant";

@Component({
    selector: 'app-sale-residues-client',
    templateUrl: './sale-residues-client.component.html',
    styleUrls: ['./sale-residues-client.component.scss']
})
export class SaleResiduesClientComponent extends BaseComponent<ResiduesClient> implements OnInit {

    public showChart = false;
    public weightOption: EChartOption;
    public theme = 'macarons';
    public dtInicial: any;
    public dtFinal: any;
    public operation = SERVICE_TYPES.COMPRA;
    private color: string;
    public type = 'K';
    public user: any;
    public filteredOptions: Observable<Customer[]>;
    public customerList = new Array<Customer>();
    @ViewChild("typebuy", {static: false}) public typebuy: MatButtonToggleGroup;

    constructor(
        public injector: Injector,
        private groupTypeService: GroupTypeService,
        private authService: AuthService,
        private customerService: CustomerService,
    ) {
        super(injector, {endpoint: URLS.TICKETS});
    }

    ngOnInit(): void {
        this.user = this.authService.user;
        super.ngOnInit();
        this.getActiveCustomers().subscribe(success => {
            this.customerList = success;
        })
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
        if (this.user.user_obj.is_staff) {
            this.filteredOptions = this.formGroup.controls.customer.valueChanges
                .pipe(
                    startWith(''),
                    map(value => value ? this._filter(value) : this.customerList.slice())
                );
        }else {
            this.getData();
        }
    }

    public changeType(value) {
        this.type = value
        this.getData();
    }

    getActiveCustomers() {
        this.customerService.clearParameter();
        this.customerService.addParameter('active', true);
        return this.customerService.getAll();
    }

    public clearField(formControl) {
        formControl.setValue('')
    }
    public getData(): void {
        this.service.clearParameter();
        this.service.addParameter('by_weight', this.type === 'K')
        this.service.addParameter('op_type', this.operation);
        if (this.user.user_obj.users.length) {
            this.service.addParameter('customer', this.user.user_obj.users[0]);
        }else{
            this.service.addParameter('customer', this.formGroup.get('customer').value.id);
        }
        if (this.dtInicial) {
            this.service.addParameter('dt_inicial', Utils.getDateToStringRequest(this.dtInicial._d));
        }
        if (this.dtFinal) {
            this.service.addParameter('dt_final', Utils.getDateToStringRequest(this.dtFinal._d));
        }
        this.service.getFromListRoute<ResiduesClient[]>('graph_by_residue')
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(response => {
                this.createChart(response);
            });
    }

    public createChart(response: ResiduesClient[]): void {
        const dataAxis = [];
        const dataSeries = [];

        response.forEach(t => {
            dataAxis.push(t.residue__name.toUpperCase());
            dataSeries.push(t.total);
        });

        let unit = this.type === 'K' ? ' Kg' : ' R$';

        this.weightOption = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: function (params, ticket, callback) {
                    return params[0].value.toLocaleString('pt') + unit;
                }
            },
            toolbox: {
                feature: {
                    saveAsImage: {
                        show: true,
                        title: 'download'
                    },
                },
                top: 'top',
                right: 15,
                orient: 'vertical',
                itemGap: 20
            },
            xAxis: {
                type: 'category',
                data: dataAxis,
                axisLabel: {
                    rotate: 35
                }
            },
            yAxis: {
                type: 'value',
                splitArea: {
                    show: true,
                    areaStyle: {
                        color: ['#f8f8f8', '#c7c3c3']
                    }
                }
            },
            series: [{
                type: 'bar',
                data: dataSeries,
            }],
            color: ['rgba(57,232,5,0.73)'],
        };

        this.showChart = response.length > 0;
    }

    createFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            customer: [null, this.user.user_obj.is_staff ? null : Validators.required ],
        });
    }
    displayFn(customer?: Customer): string | undefined {
        return customer ? customer.name : undefined;
    }

    private _filter(name: string): Customer[] {
        let filterValue = name;
        if (typeof name === 'string') {
            filterValue = name.toLowerCase();
        }
        return this.customerList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

}
