import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from "../base.component";
import {Customer} from "../models/models.model";
import {MatTable} from "@angular/material/table";
import {URLS} from "../app.urls";

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent extends BaseComponent<Customer> implements AfterViewInit, OnInit {
  @ViewChild(MatTable) table: MatTable<Customer>;

  displayedColumns = ['group_name', 'name', 'action'];

  constructor(
      public injector: Injector,
  ) {
    super(
        injector,
        {
          endpoint: URLS.RESIDUE,
          pagination: true,
          searchOnInit: true,
          keepFilters: true,
          title: 'Produtos'
        });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.table.dataSource = this.dataSource;
  }

  public createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      name: [null],
    });
  }

  public search(event, sort): void {
    this.service.clearParameter();
    if (this.v.name) {
      this.service.addParameter('name', this.v.name);
    }
    super.search(event, sort);
  }
}
