import {Component, ElementRef, Injector, ViewChild} from '@angular/core';
import {License} from "../../models/models.model";
import {FormBuilder, Validators} from "@angular/forms";
import {URLS} from "../../app.urls";
import {BaseComponent} from "../../base.component";
import {FileUploadService} from "../../services/services.service";
import {MESSAGES} from "../../app.constant";

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss']
})
export class LicenseComponent extends BaseComponent<License>{

  public object: License = new License();
  public fileToUpload: any;
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
      private fb: FormBuilder,
      public injector: Injector,
      private uploadService: FileUploadService,
  ) {
    super(
        injector, {
          pk: 'id',
          endpoint: URLS.LICENSE,
          nextRoute: '/license-list',
          nextRouteUpdate: '/license-list',
          retrieveOnInit: true,
          title: 'Licença'
        });
  }

  public createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      name: [null, Validators.required],
      id: [],
      number: [null, Validators.required],
      customer: [],
      dt_shipping: [null, Validators.required],
      dt_due: [],
      validity_days: [null, Validators.required],
      active: [true]
    });
  }

  saveOrUpdateFormData(){
    super.saveOrUpdateFormData(call => {
      this.toast.success(MESSAGES.success_title, MESSAGES.saved_successfully);
      this.postFile(this.fileToUpload);
    })
  }
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  postFile(fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append("license", this.object["id"].toString());
    this.uploadService.save(formData).subscribe(
        value => {
          this.fileInput.nativeElement.value = "";
          this.fileToUpload = null;
        });
  }
}
