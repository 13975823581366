import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {shareReplay, tap} from "rxjs/operators";
import {NavigationExtras, Router} from "@angular/router";
import jwt_decode from 'jwt-decode';
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {User} from '../models/user';
import {URLS} from '../app.urls';


interface AuthPayload {
  user_id: number;
  username: string;
  exp: number;
  orig_iat: number;
  is_staff: boolean;
}

@Injectable()
export class AuthService {

  private storage = localStorage;
  private readonly urlBase: string;
  private readonly urlAuth: string;
  private readonly urlUser: string;
  private urlToken: string;
  private urlMenu: string;

  constructor(
      public http: HttpClient,
      public router: Router) {

    this.urlBase = environment.urlBase;
    this.urlAuth = environment.urlAuth;
    this.urlUser = `${this.urlBase}${URLS.USER}`;
    this.urlToken = `${this.urlAuth}${URLS.JWT_TOKEN}`;
  }

  get user(): AuthPayload {

    const token = this.storage.getItem("tokenGir");
    const payload = <AuthPayload>jwt_decode(token);
    return payload;
  }

  public login(user: User) {
    return this.http.post(this.urlToken, user)
        .pipe(
            tap(response => this.setToken(response)),
            shareReplay(),
        );
  }

  public logout(reload?: boolean, redirect?: boolean, extras?: NavigationExtras): void {
    this.storage.removeItem("tokenGir");
    if (reload) {
      location.reload();
    }
    if (redirect) {
      this.router.navigate(["login"], extras).then();
    }
  }

  public isLoggedIn() {
    return !!this.storage.getItem("tokenGir");
  }

  public isLoggedOut() {
    return !this.isLoggedIn();
  }

  public changePassword(user: User, data): Observable<any> {
    const headers = new HttpHeaders({
      "Authorization": "Bearer ".concat(this.storage.getItem("tokenGir"))
    });

    const url = `${this.urlUser}${user.id}/change_password/`;
    return this.http.patch<any>(url, data, {"headers": headers});
  }

  private setToken(authResponse) {
    if (authResponse) {
      this.storage.setItem("tokenGir", authResponse.token);
    }
  }
}
