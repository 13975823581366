import {Component, OnInit} from "@angular/core";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {FullLoadService} from "../services/full-load.service";

@Component({
    selector: "app-full-load",
    templateUrl: "./full-load.component.html",
    styleUrls: ["./full-load.component.scss"]
})
export class FullLoadComponent implements OnInit {
    public show = false;

    constructor(private service: FullLoadService) {
    }

    public ngOnInit(): void {
        this.service.show.pipe(
            debounceTime(10),
            distinctUntilChanged()
        ).subscribe(n => {
            this.show = Boolean(n);
        });
    }

}
