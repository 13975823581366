<!-- Colored FAB button -->
<button mat-fab id="add" [routerLink]="['new']">
    <i class="material-icons">add</i>
</button>

<div fxLayout="column" fxLayoutGap="20px">

    <form [formGroup]="formGroup" (submit)="searchButton(paginator, sort)">
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex *ngIf="user.is_staff">
                <input #clientInput id="name_search" matInput placeholder="Cliente" formControlName="customer">
                <button  mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearField(formGroup.controls['customer'])">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field fxFlex>
                <input #mtrInput id="mtr_search" matInput placeholder="MTR" formControlName="mtr">
                <button  mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearField(formGroup.controls['mtr'])">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field fxFlex>
                <input #romaneioInput id="romaneio_search" matInput placeholder="Romaneio" formControlName="no_packing_list">
                <button  mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearField(formGroup.controls['no_packing_list'])">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field fxFlex>
                <input #ticketInput id="ticket_search" matInput placeholder="Ticket" formControlName="ticket_number">
                <button  mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearField(formGroup.controls['ticket_number'])">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Tipo</mat-label>
                <select matNativeControl formControlName="service_type">
                    <option value="" selected>Todos</option>
                    <option value="C">Compra</option>
                    <option value="V">Venda</option>
                </select>
            </mat-form-field>
            <div class="fix-align" >
                <mat-checkbox formControlName="is_service">Serviço</mat-checkbox>
            </div>
            <mat-form-field fxFlex>
                <mat-label>Data inicial</mat-label>
                <input id="data_registro_inicial" dateFormat="YYYY-MM-DD" matInput [matDatepicker]="picker"
                       formControlName="date_weight_final_after">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-label>Data Final</mat-label>
                <input id="data_registro_final" dateFormat="YYYY-MM-DD" matInput [matDatepicker]="picker2"
                       formControlName="date_weight_final_before">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
            <div>
                <button mat-icon-button class="bt-search" type="submit" color="primary">
                    <i class="material-icons">search</i>
                </button>
            </div>
        </div>
    </form>

    <div class="mat-elevation-z1">
        <table mat-table class="full-width-table" matSort aria-label="Elements">
            <ng-container matColumnDef="no_packing_list">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Romaneio</th>
                <td mat-cell *matCellDef="let row">
                    <a *ngIf="user.is_staff" [routerLink]="[row.id]"> {{row.no_packing_list}}</a>
                    <span *ngIf="!user.is_staff">{{row.no_packing_list}}</span>
                </td>
            </ng-container>
            <!-- Id Column -->
            <ng-container matColumnDef="ticket_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Ticket</th>
                <td mat-cell *matCellDef="let row">
                    <a *ngIf="user.is_staff" [routerLink]="[row.id]"> {{row.ticket_number}}</a>
                    <span *ngIf="!user.is_staff">{{row.ticket_number}}</span>
                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="mtr">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>MTR</th>
                <td mat-cell *matCellDef="let row">{{row.mtr}}</td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="customer_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
                <td mat-cell *matCellDef="let row">{{row.customer_name}}</td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="type_service">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
                <td mat-cell *matCellDef="let row">{{row.type_service}}</td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="weight">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Peso (Kg)</th>
                <td mat-cell *matCellDef="let row">{{row.weight | number}}</td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="date_weight_final">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
                <td mat-cell *matCellDef="let row">{{row.date_weight_final | date:'shortDate'}}</td>
            </ng-container>

            <ng-container matColumnDef="download">
                <th mat-header-cell *matHeaderCellDef>Download</th>
                <td mat-cell *matCellDef="let row">
                    <mat-icon *ngIf="row.ticket_file.length" (click)="download(row)" style="cursor: pointer;">input</mat-icon>
                    <span *ngIf="!(row.ticket_file.length)">Sem arquivo</span>
                </td>
            </ng-container>

            <!-- Name Column -->

            <ng-container matColumnDef="action" *ngIf="user.is_staff">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let element" align="end">
                    <button mat-button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="delete(element.id, element.name)" >
                            <mat-icon>delete</mat-icon>
                            <span>Remover</span>
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <!--        {{paginator.pageSize}} - pageSize-->
        <mat-paginator #paginator [length]="paginator.length"
                       [pageSize]="paginator.pageSize" [pageSizeOptions]="paginator.pageSizeOptions"
                       (page)="search($event, sort)">
        </mat-paginator>
    </div>

</div>
