import {Component} from '@angular/core';
import {map} from 'rxjs/operators';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {TitlePageService} from "../shared/title-page.service";
import { LicenseService} from "../services/services.service";
import {MatTableDataSource} from "@angular/material/table";
import EChartOption = echarts.EChartOption;
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  /** Based on the screen size, switch from standard to one column per row */
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({matches}) => {
        if (matches) {
          return [
            {id: 1, title: 'Card 1', cols: 1, rows: 1},
            {id: 2, title: 'Tickets de compra', cols: 1, rows: 1},
            {id: 3, title: 'Tickets de venda', cols: 1, rows: 1},
          ];
        }

        return [
          {id: 1, title: 'Quantidade de resíduos por cliente', cols: 2, rows: 2},
          {id: 2, title: 'Tickets de compra', cols: 1, rows: 2},
          {id: 3, title: 'Tickets de venda', cols: 1, rows: 2},
        ];
      })
  );

  chartOptionPie: EChartOption = {
    series: [{
      name: 'Vendas por categoria',
      data: [
        {value: 820, name: 'Lixo'},
        {value: 220, name: 'Papelão'},
        {value: 580, name: 'Metais'},
        {value: 400, name: 'outros'}
      ],
      type: 'pie',
      top: 0,
      bottom: '30%',
      radius: '55%',
      center: ['50%', '50%'],
    }]
  };

  chartOptionLine: EChartOption = {
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    },
    yAxis: {
      type: 'value'
    },
    series: [{
      data: [820, 932, 901, 934, 1290, 1330],
      type: 'line',
    }]
  };

  theme = 'macarons';

  public displayedColumns = ['client', 'license', 'expire_date'];
  public dataSource = [];
  public user: any

  constructor(
      private breakpointObserver: BreakpointObserver,
      private title: TitlePageService,
      private licenseService: LicenseService,
      private authService: AuthService,
  ) {
    this.title.name.next('Dashboard');
    this.loadExpireLicense();
    this.user = this.authService.user
  }

  public send() {
    this.licenseService.getFromListRoute('send_email').subscribe(sc => {
    })
  }

  private loadExpireLicense() {
    //this.clientLicenseService.getFromListRoute('get_liceses_expired').subscribe((sc: []) => {
    // this.dataSource = sc;
    //})
  }
}
