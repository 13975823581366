<div fxLayout="column" fxLayoutGap="20px">

    <div class="title" *ngIf="this.id_client">Caminhões</div>

    <form novalidate [formGroup]="formGroup">
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <input matInput type="text" formControlName="plate" placeholder="Placa" id="placa">
            </mat-form-field>
            <mat-form-field fxFlex>
                <input matInput type="text" formControlName="model" placeholder="Modelo" id="modelo">
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-select placeholder="Tipo" formControlName="type">
                    <mat-option value="R">Rol-on</mat-option>
                    <mat-option value="M">Mulk</mat-option>
                    <mat-option value="P">Poliguincho</mat-option>
                </mat-select>
            </mat-form-field>
            <div>
                <button mat-icon-button mat-raised-button color="accent" id="add_table" type="button"
                        (click)="saveClientTruck()">
                    <i class="material-icons">add</i>
                </button>
            </div>
        </div>
    </form>

    <div class="mat-elevation-z1">
        <table mat-table class="full-width-table" [dataSource]="dataSource" matSort aria-label="Elements">

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Placa</th>
                <td mat-cell *matCellDef="let row">{{row.plate}}</td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="expire">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Modelo</th>
                <td mat-cell *matCellDef="let row">{{row.model}}</td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let element" align="end">
                    <button mat-icon-button color="accent" type="button" (click)="delete(element.id, element.name)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator #paginator
                       [length]="dataSource?.data.length"
                       [pageIndex]="0"
                       [pageSize]="50"
                       [pageSizeOptions]="[25, 50, 100, 250]">
        </mat-paginator>
    </div>

</div>
