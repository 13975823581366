import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FileUploadService, LicenseService} from "../../services/services.service";
import {License} from "../../models/models.model";
import {Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {MESSAGES} from "../../app.constant";
import {ToastService} from "../../services/toast.service";
import {TitlePageService} from "../../shared/title-page.service";
import {DialogComponent} from "../../shared/dialog/dialog.component";
import {EVENT} from "../../base.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-client-license',
  templateUrl: './client-license.component.html',
  styleUrls: ['./client-license.component.scss']
})
export class ClientLicenseComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public dataSource = new MatTableDataSource<any>()
  public formGroup: FormGroup;
  public object = {};
  public licenseList = new Array<License>();
  public filteredLicenses: Observable<License[]>;
  public id_client: string;
  public fileToUpload: any;
  @ViewChild('fileInput') fileInput: ElementRef;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name', 'expire', 'file', 'action'];
  private unsubscribe = new Subject();

  constructor(
      public formBuilder: FormBuilder,
      private licenseService: LicenseService,
      private activatedRoute: ActivatedRoute,
      public toast: ToastService,
      private uploadService: FileUploadService,
      private title: TitlePageService,
      private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.createFormGroup();
    if (this.activatedRoute.snapshot.params["action"] !== "new") {
      this.id_client = this.activatedRoute.snapshot.params["action"];
      if (this.id_client === 'default') {
        this.id_client = null;
      }
      this.getLicenses();
    }

  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      name: [null, Validators.required],
      id: [],
      number: [null, Validators.required],
      customer: [],
      dt_shipping: [null, Validators.required],
      dt_due: [],
      validity_days: [null, Validators.required],
      active: [true]
    });
  }

  getLicenses() {
    this.licenseService.clearParameter();
    this.licenseService.addParameter('active', true);
    if (this.id_client) {
      this.licenseService.addParameter('customer', this.id_client.toString());
    }else{
      this.licenseService.addParameter('customer', null);
    }
    this.licenseService.getAll().subscribe(result => {
      this.dataSource.data = result;
    });
  }

  private _filterLicense(name: string): License[] {
    let filterValue = name;
    if (typeof name === 'string') {
      filterValue = name.toLowerCase();
    }
    return this.licenseList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  saveClientLicense() {
    this.licenseService.clearParameter();
    this.formGroup.controls.customer.setValue(this.id_client);
    this.object = this.formGroup.getRawValue();
    const formData = new FormData();
    Object.keys(this.object).forEach(key => {
      const value = this.object[key];
      if (value !== null && typeof value === 'object') {
        formData.append(key, value === undefined ? '' : value.id);
      } else {
        formData.append(key, value === null || value === undefined ? '' : value);
      }
    });
    if (this.object['id']) {
      this.licenseService.update(this.object['id'], formData).subscribe(success => {
        this.getLicenses();
      });
    } else {
      this.save(formData);
    }
  }


  private save(aObject): void {
    this.licenseService.save(aObject)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
            response => {
              this.object = response;
              this.toast.success(MESSAGES.success_title, MESSAGES.saved_successfully);
              this.postFile(this.fileToUpload);
              this.getLicenses();
            }
        );
  }

  displayFnR(license?: License): string | undefined {
    return license ? license.name : undefined;
  }

  public delete(pk: any, description: string, callback?: (event) => void): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '275px',
      // height: '220px',
      data: {
        id: pk,
        title: 'Apagar',
        message: 'Deletar ',
        description: description
      }
    });

    dialogRef.afterClosed()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(result => {
          if (result) {
            this.licenseService.delete(pk)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                      this.toast.success(MESSAGES.success_title, MESSAGES.deleted_successfully);
                      this.getLicenses();
                      if (callback) {
                        callback(EVENT.DELETE);
                      }
                    }
                );
          }
        });
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  postFile(fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append("license", this.object["id"]);
    this.uploadService.save(formData).subscribe(
        value => {
          this.fileInput.nativeElement.value = "";
          this.fileToUpload = null;
        });
  }
}
