<mat-card class="my-mat-card">
    <mat-card-header fxLayout="start center">
        <mat-card-title class="title" fxLayoutAlign="space-between center">
            <div>Produtos por cliente/fornecedor por mês</div>

            <mat-button-toggle-group #typebuy="matButtonToggleGroup"
                                     *ngIf="user.user_obj.is_staff"
                                     class="mat-elevation-z2" [value]="type" (change)="changeType(typebuy.value)">
                <mat-button-toggle class="toggle-bottom" value="K">Kg
                </mat-button-toggle>
                <mat-button-toggle class="toggle-top" value="R">R$
                </mat-button-toggle>
            </mat-button-toggle-group>
        </mat-card-title>
    </mat-card-header>

    <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex *ngIf="user.user_obj.is_staff">
            <mat-select [(ngModel)]="operation" name="op" (ngModelChange)="getData()">
                <mat-option value="C"> Compra</mat-option>
                <mat-option value="V"> Venda</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex>
            <mat-select [(ngModel)]="groupType" name="grupo" (ngModelChange)="getData()">
                <mat-option *ngFor="let gruop of groupTypes" [value]="gruop">
                    {{gruop.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div [formGroup]="formGroup" fxLayoutGap="20px" fxFlex="65">
            <mat-form-field fxFlex [formGroup]="formGroup" *ngIf="user.user_obj.is_staff">
                <input #clientInput matInput type="text" placeholder="Cliente" formControlName="customer" [matAutocomplete]="auto">
                <button  mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearField(formGroup.controls['customer'])">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>

            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-label>Mês inicial</mat-label>
                <mat-select formControlName="m_initial">
                    <mat-option *ngFor="let month of months; index as i" [value]="i+1">{{month}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-label>Mês final</mat-label>
                <mat-select formControlName="m_final">
                    <mat-option *ngFor="let month of months; index as i" [value]="i+1">{{month}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-label>Ano</mat-label>
                <input matInput type="text" placeholder="Ano" formControlName="year">
            </mat-form-field>
        </div>
        <button class="bt-search" color="primary" mat-icon-button type="button" (click)="getData()">
            <mat-icon>search</mat-icon>
        </button>
    </div>

    <div fxLayout="row" *ngIf="showChart">
        <div fxLayout="column" class="border" fxFlex [theme]="theme" echarts [options]="weightOption"></div>
    </div>
</mat-card>
