import {AfterViewInit, Component, Injector, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {BaseComponent} from "../../../base.component";
import {Residue, ResiduesQuantity, Ticket} from "../../../models/models.model";
import {MatTable} from "@angular/material/table";
import {URLS} from "../../../app.urls";
import {Observable} from "rxjs";
import {ResidueService, TablePriceService, TicketService} from "../../../services/services.service";
import {map, startWith} from "rxjs/operators";
import {SERVICE_TYPES} from "../../../app.constant";

@Component({
  selector: 'app-ticket-product',
  templateUrl: './ticket-product.component.html',
  styleUrls: ['./ticket-product.component.scss']
})
export class TicketProductComponent extends BaseComponent<ResiduesQuantity> implements AfterViewInit {

  @ViewChild(MatTable) table: MatTable<ResiduesQuantity>;
  @Input() ticket: Ticket;
  displayedColumns = ['product', 'weight', 'price_unit', 'price', 'action'];
  public residueList = new Array<Residue>();
  public filteredResidues: Observable<Residue[]>;
  public pesoDiff: number;
  public valorTotal = 0;
  public valor = 0
  public pesoTotal = 0
  public object: ResiduesQuantity = new ResiduesQuantity();
  public treatment: string;

  constructor(
      public injector: Injector,
      private tablePrice: TablePriceService,
      private residueService: ResidueService,
      private ticketService: TicketService,
  ) {
    super(injector, {
      endpoint: URLS.TICKET_PRODUCT,
      pagination: true,
      searchOnInit: false,
      noUpdateFormOnSave: true,
      title: 'Tickets',
    });
    this.getActiveResidues();
  }

  getActiveResidues() {
    this.residueService.clearParameter();
    this.residueService.addParameter('active', true);
    this.residueService.getAll().subscribe((residues) => {
      this.residueList = residues;
      this.filteredResidues = this.formGroup.controls.residue.valueChanges
          .pipe(
              startWith(''),
              map(value => value ? this._filterResidue(value) : this.residueList.slice())
          );
    });
  }

  public createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      residue: [null],
      weight: [null],
      treatment: [null],
      value: [null],
      value_unit: [0],
      ticket: [null],
      without_value: [false]
    });
  }

  private _filterResidue(name: string): Residue[] {
    let filterValue = name;
    if (typeof name === 'string') {
      filterValue = name.toLowerCase();
    }
    return this.residueList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.table.dataSource = this.dataSource;
    this.formGroup.controls.ticket.setValue(this.ticket);
    if (this.ticket.id != undefined) {
      this.search(this.paginator);
    }
  }

  public search($event, sort?): void {
    this.service.clearParameter();
    this.service.addParameter("ticket", this.ticket.id);
    super.search(this.paginator, null, () => {
      this.calculateTotal(this.dataSource.data);
    });
  }

  onResponse(response: ResiduesQuantity, event: number, callback?: (event) => void) {
    super.onResponse(response, event, callback);
    this.calculateTotal(response);
  }

  calculateTotal(data) {
    this.valorTotal = 0;
    this.pesoTotal = 0;
    if (data.length) {
      data.forEach(item => {
        this.valorTotal += item.value;
        this.pesoTotal += item.weight;
      })
    }
  }

  displayFnR(residue?: Residue): string | undefined {
    return residue ? residue.name : undefined;
  }

  getResiduePrice(noClient?) {
    this.tablePrice.clearParameter();
    const customer = this.ticket.customer_obj;
    const residue = this.formGroup.get('residue').value;
    if (customer && !noClient) {
      this.tablePrice.addParameter('customer', customer.id);
    } else {
      this.tablePrice.addParameter('customer', null);
    }
    if (!Array.isArray(residue) && residue !== null && typeof residue !== "string") {
      this.tablePrice.addParameter('residue', residue.id);
    }
    if (customer && !Array.isArray(residue) && residue !== undefined && residue !== null && typeof residue !== "string" && typeof customer !== 'number') {
      this.tablePrice.getAll().subscribe(success => {
        if (success.length) {
          if (!this.formGroup.get('without_value').value) {
            if ((this.ticket.service_type === SERVICE_TYPES.COMPRA && success[0].price !== 0) || (this.ticket.service_type !== SERVICE_TYPES.COMPRA && success[0].price_sell !== 0)) {
              if (this.ticket.service_type === SERVICE_TYPES.COMPRA) {
                this.formGroup.controls.value_unit.setValue(success[0].price);
                this.valor = Number((success[0].price * this.formGroup.controls.weight.value).toFixed(3));
              } else {
                this.formGroup.controls.value_unit.setValue(success[0].price_sell);
                this.valor = Number((success[0].price_sell * this.formGroup.controls.weight.value).toFixed(3));
              }
            } else {
              this.toast.warning('Preço', 'Não foi encontrado preço para este produto');
            }
          }
          this.formGroup.get('value').setValue(this.valor | 0);
          this.formGroup.controls.value.setValue(this.valor);
          this.saveOrUpdateTicketProduto()

        } else if (!noClient) {
          noClient = !noClient;
          this.getResiduePrice(noClient);
        } else {
          this.toast.warning('Preço', 'Não foi encontrado preço para este produto')
          this.saveOrUpdateTicketProduto()
        }
      });
    }
  }

  public saveOrUpdateTicketProduto() {
    this.saveOrUpdateFormData(event => {
      this.formGroup.reset({ticket: this.ticket}, {onlySelf: true, emitEvent: false});
      this.service.addParameter("ticket", this.ticket.id);
      this.search(this.paginator);
    });
  }

  addProduct() {
    this.getResiduePrice(false)
  }
}
