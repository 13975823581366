<div fxLayout="column" fxLayoutGap="20px">

    <div class="title" *ngIf="this.id_client">Licenças</div>

    <form novalidate [formGroup]="formGroup">
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field>
                <input id="number" matInput type="text" formControlName="number" placeholder="Numero">
            </mat-form-field>
            <mat-form-field fxFlex>
                <input id="id" matInput type="text" placeholder="Identificação" formControlName="name" >
            </mat-form-field>
            <mat-form-field fxFlex="200px">
                <mat-label>Data expedição</mat-label>
                <input id="data_registro_inicial" dateFormat="YYYY-MM-DD" matInput [matDatepicker]="picker"
                       formControlName="dt_shipping">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxFlex="200px">
                <mat-label>Data vencimento</mat-label>
                <input id="data_registro_vencimento" dateFormat="YYYY-MM-DD" matInput [matDatepicker]="picker2"
                       formControlName="dt_due">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxFlex>
                <input id="days_notification" matInput type="number" placeholder="Dias para notificação" formControlName="validity_days" >
            </mat-form-field>
        </div>
        <div class="row">
            <div fxLayour="column">
                <div class="row form-group">
                    <div class="col">
                        <p>CARREGAR NOVO ARQUIVO</p>
                        <div class="line"></div>
                    </div>
                </div>
                <div class="row">
                    <mat-card-actions align="start" class="col">
                        <input type="file" #fileInput id="file" hidden="true"
                               (change)="handleFileInput($event.target.files)">
                        <button (click)="fileInput.click()" type="button" mat-raised-button color="accent">Escolher
                            arquivo</button>
                    </mat-card-actions>
                </div>
                <div class="mat-elevation-z1 chosen-file row" *ngIf="fileToUpload != null">
                    <div class="col">
                        <p>{{ fileToUpload.name }}</p>
                    </div>
                </div>
            </div>
            <div fxLayout="column">
                <button mat-icon-button mat-raised-button color="accent" id="add_table" type="button"
                        (click)="saveClientLicense()" [disabled]="formGroup.invalid">
                    <i class="material-icons">add</i>
                </button>
            </div>
        </div>
    </form>

    <div class="mat-elevation-z1">
        <table mat-table class="full-width-table" [dataSource]="dataSource" matSort aria-label="Elements">

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Licença</th>
                <td mat-cell *matCellDef="let row">{{row.number}} - {{row.name}}</td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="expire">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Valido até</th>
                <td mat-cell *matCellDef="let row">{{row.dt_due | date:'shortDate'}}</td>
            </ng-container>


            <ng-container matColumnDef="file">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Arquivo</th>
                <td mat-cell *matCellDef="let row">
                    <a target="_blank" *ngIf="row.licenses_file.length" [href]="row.licenses_file.length? row.licenses_file[0].file : ''"><mat-icon>folder_open</mat-icon></a>
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let element" align="end">
                    <button mat-icon-button color="accent" type="button" (click)="delete(element.id, element.name)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator #paginator
                       [length]="dataSource?.data.length"
                       [pageIndex]="0"
                       [pageSize]="50"
                       [pageSizeOptions]="[25, 50, 100, 250]">
        </mat-paginator>
    </div>

</div>
