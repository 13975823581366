import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild} from '@angular/core';
import {BreakpointObserver, Breakpoints, MediaMatcher} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {AuthService} from "../services/auth.service";
import {TitlePageService} from "../shared/title-page.service";
import {MatSidenav} from "@angular/material/sidenav";
import {User} from "../models/user";

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnDestroy, AfterViewInit {
  @ViewChild('drawer') drawer: MatSidenav;
  public titleName: any;
  public user: any;
  mobileQuery: MediaQueryList;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  private _mobileQueryListener: () => void;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private title: TitlePageService,
    private authService: AuthService,
    media: MediaMatcher,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.title.name.subscribe(n => {
      this.titleName = n;
    });
  }

  public logout(): void {
    this.authService.logout(false, true);
  }

  public close() {
    this.drawer.close();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngAfterViewInit(): void {
    this.user = this.authService.user
  }

}
