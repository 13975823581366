<mat-card *ngIf="showChart" class="my-mat-card">
    <mat-card-header fxLayout="start center">
        <mat-card-title class="title" fxLayoutAlign="space-between center">
            <div>Tickets de Compra</div>

            <mat-button-toggle-group #typebuy="matButtonToggleGroup"
                                     *ngIf="user.user_obj.is_staff"
                                     class="mat-elevation-z2" [value]="type" (change)="changeType(typebuy.value)">
                <mat-button-toggle class="toggle-bottom" value="K">Kg
                </mat-button-toggle>
                <mat-button-toggle class="toggle-top" value="R">R$
                </mat-button-toggle>
            </mat-button-toggle-group>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <mat-label>Data inicial</mat-label>
                <input id="tcdtinicial" matInput [matDatepicker]="picker" [(ngModel)]="dtInicial">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-label>Data final</mat-label>
                <input id="tcdtfinal" matInput [matDatepicker]="picker2" [(ngModel)]="dtFinal">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
            <button mat-icon-button color="primary" class="bt-search" type="button" (click)="getData()">
                <mat-icon>search</mat-icon>
            </button>
        </div>
        <div fxLayout="row">
            <div class="border" fxLayout="row" fxFlex [theme]="theme" echarts [options]="weightOption"></div>
        </div>
    </mat-card-content>
</mat-card>

