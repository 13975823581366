<div fxLayout="column" fxLayoutGap="20px">
    <div>
        <button mat-button color="primary" [routerLink]="['/ticket-list']">
            <mat-icon class="ic-back">chevron_left</mat-icon>
            VOLTAR
        </button>
    </div>

    <form [formGroup]="formGroup" novalidate (ngSubmit)="saveOrUpdateFormData()">
        <mat-card>
            <mat-card-header>
                <mat-card-title class="title">Registrar ticket</mat-card-title>
            </mat-card-header>
            <mat-card-content fxLayout="column" fxLayoutGap="40px">
                <div>
                    <div class="row" fxLayoutGap="20px">
                        <mat-form-field fxFlex>
                            <input id="romaneio" matInput placeholder="No. Romaneio" formControlName="no_packing_list">

                        </mat-form-field>
                        <mat-form-field fxFlex>
                            <input id="no_ticket" matInput placeholder="No. Ticket" formControlName="ticket_number">
                        </mat-form-field>
                    </div>
                    <div class="row" fxLayoutGap="20px">
                        <mat-form-field fxFlex>
                            <input matInput type="text" placeholder="Cliente" formControlName="customer"
                                   [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                                              (optionSelected)="getActiveTrucks()">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field fxFlex>
                            <mat-label>Data</mat-label>
                            <input id="data_registro_final" dateFormat="YYYY-MM-DD" matInput [matDatepicker]="picker2"
                                   formControlName="date_weight_final" placeholder="Data">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="row" fxLayoutGap="20px">
                        <mat-form-field fxFlex>
                            <input id="mtr" matInput formControlName="mtr" placeholder="MTR">
                        </mat-form-field>
                        <mat-form-field fxFlex>
                            <input matInput type="text" placeholder="Destinador" formControlName="recipient"
                                   [matAutocomplete]="auto2">
                            <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn">
                                <mat-option *ngFor="let option of filteredOptionsRecipient | async" [value]="option">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field fxFlex>
                            <textarea matInput placeholder="Obs.:" formControlName="comments"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="row" fxLayoutGap="20px">
                        <mat-form-field fxFlex>
                            <mat-select placeholder="Caminhão" formControlName="truck">
                                <mat-option *ngFor="let truck of truckList" [value]="truck.id">
                                    {{TRUCK_TYPES[truck.type]}} / {{ truck.plate }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="formGroup.controls['truck'].hasError('required')">
                                State is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex>
                            <input id="motorista" matInput placeholder="Motorista" formControlName="driver_name">
                        </mat-form-field>
                    </div>
                </div>

                <div fxLayout="column" fxLayoutGap="15px">
                    <div class="row">
                        <div class="col">
                            <p>PESAGEM INICIAL</p>
                            <div class="line"></div>
                        </div>
                    </div>
                    <div class="row" fxLayoutGap="20px">
                        <mat-form-field fxFlex>
                            <input id="peso_inicial" matInput type="number" formControlName="weight_initial"
                                   placeholder="Peso registrado (Kg)">
                        </mat-form-field>
                        <mat-form-field fxFlex>
                            <input id="operador_i" matInput type="text" formControlName="operator_initial"
                                   placeholder="Operador">
                        </mat-form-field>
                        <!-- <mat-form-field fxFlex>
                            <mat-label>Data inicial</mat-label>
                            <input id="data_registro_inicial" dateFormat="YYYY-MM-DD" matInput [matDatepicker]="picker"
                                   formControlName="date_weight_initial">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field> -->
                    </div>
                </div>

                <div fxLayout="column" fxLayoutGap="15px">
                    <div class="row">
                        <div class="col">
                            <p>PESAGEM FINAL</p>
                            <div class="line"></div>
                        </div>
                    </div>
                    <div class="row" fxLayoutGap="20px">
                        <mat-form-field fxFlex>
                            <input id="peso_final" matInput type="number" placeholder="Peso final"
                                   formControlName="weight_final">
                            <mat-error *ngIf="formGroup.controls['weight_final'].hasError('required')">
                                Peso é <strong>obrigatório</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex>
                            <input id="operador_final" matInput type="text" formControlName="operator_final"
                                   placeholder="Operador">
                        </mat-form-field>
                    </div>
                </div>

                <div fxLayout="column" fxLayoutGap="15px">
                    <div class="row">
                        <div class="col">
                            <p>TIPO DO TICKET</p>
                            <div class="line"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <mat-radio-group formControlName="service_type">
                                <mat-radio-button value="C">Compra</mat-radio-button>
                                <mat-radio-button value="V">Venda</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col">
                            <mat-checkbox formControlName="is_service">Serviço</mat-checkbox>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="15px" *ngIf="files.length > 0">
                    <div class="row">
                        <div class="col">
                            <p>ARQUIVOS</p>
                            <div class="line"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="mat-elevation-z1 col">
                            <table mat-table class="full-width-table" [dataSource]="files" matSort aria-label="Elements">
                                <ng-container matColumnDef="file">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome do arquivo</th>
                                    <td mat-cell *matCellDef="let element"> <a [href]="element.file" target="_blank" [download]="element.filename"><p> {{ element.filename }} </p></a></td>
                                </ng-container>
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                    <td mat-cell *matCellDef="let element"  align="end">
                                        <button mat-button mat-icon-button [matMenuTriggerFor]="menu" type="button">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="deleteFile(element)">
                                                <mat-icon>delete</mat-icon>
                                                <span>Remover</span>
                                            </button>
                                        </mat-menu>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div fxLayour="column">
                    <div class="row form-group">
                        <div class="col">
                            <p>CARREGAR NOVO ARQUIVO</p>
                            <div class="line"></div>
                        </div>
                    </div>
                    <div class="row">
                        <mat-card-actions align="start" class="col">
                            <input type="file" #fileInput id="file" hidden="true"
                                   (change)="handleFileInput($event.target.files)">
                            <button (click)="fileInput.click()" type="button" mat-raised-button color="accent">Escolher
                                arquivo</button>
                        </mat-card-actions>
                    </div>
                    <div class="mat-elevation-z1 chosen-file row" *ngIf="fileToUpload != null">
                        <div class="col">
                            <p>{{ fileToUpload.name }}</p>
                        </div>
                    </div>
                </div>
                <!--      <div class="row">-->
                <!--        <div class="col">-->
                <!--          <p>VALOR DO TICKET CALCULADO (R$)</p>-->
                <!--          <div class="line"></div>-->
                <!--          <div>&nbsp;</div>-->
                <!--          <h3>{{formGroup.controls.value.value | currency:'BRL'}}</h3>-->
                <!--        </div>-->
                <!--        <div class="col">-->
                <!--          <p>Peso</p>-->
                <!--          <div class="line"></div>-->
                <!--          <div>&nbsp;</div>-->
                <!--          <h3>Kg: {{pesoDiff}}</h3>-->
                <!--        </div>-->
                <!--      </div>-->
            </mat-card-content>
            <mat-card-actions align="end">
                <button mat-raised-button color="primary" type="button"
                        [routerLink]="['/ticket-list']">Cancelar</button>
                <button [disabled]="formGroup.invalid" mat-raised-button color="accent" type="submit">Salvar</button>
            </mat-card-actions>
        </mat-card>
    </form>

    <mat-card *ngIf="object.id">
        <mat-card-header>
            <mat-card-title class="title">Resíduo(s)</mat-card-title>
        </mat-card-header>
        <app-ticket-product [ticket]="object"></app-ticket-product>
    </mat-card>

</div>
