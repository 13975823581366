<div class="load-container" *ngIf="show">
    <div fxLayout="row" fxFill fxLayoutAlign="center center">
        <div fxLayout="column">
            <label>{{"loading"}}...</label>
            <mat-progress-spinner
                    [color]="'primary'"
                    [mode]="'indeterminate'">
            </mat-progress-spinner>
        </div>
    </div>
</div>
