import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MESSAGES} from 'src/app/app.constant';
import {EVENT} from 'src/app/base.component';
import {User} from 'src/app/models/user';
import {CustomerService, UserService} from 'src/app/services/services.service';
import {ToastService} from 'src/app/services/toast.service';
import {DialogComponent} from 'src/app/shared/dialog/dialog.component';
import {TitlePageService} from 'src/app/shared/title-page.service';

@Component({
  selector: 'app-client-user',
  templateUrl: './client-user.component.html',
  styleUrls: ['./client-user.component.scss']
})
export class ClientUserComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public dataSource = new MatTableDataSource<any>()
  public formGroup: FormGroup;
  public object = {};
  public usersList = new Array<User>();
  public id_client: string;

  displayedColumns = ['user', 'included'];
  displayedColumnsSelected = ['user', 'action'];
  private unsubscribe = new Subject();

  constructor(
      public formBuilder: FormBuilder,
      private userService: UserService,
      private customerService: CustomerService,
      private activatedRoute: ActivatedRoute,
      public toast: ToastService,
      private title: TitlePageService,
      private dialog: MatDialog,
  ) {
  }


  ngOnInit(): void {
    this.createFormGroup();
    if (this.activatedRoute.snapshot.params["action"] !== "new") {
      this.id_client = this.activatedRoute.snapshot.params["action"];
      if (this.id_client === 'default') {
        this.id_client = null;
      }
      this.getUsers();
    }
  }

  public createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      customer: [],
      users: []
    });
  }

  getUsers() {
    let users = new Array<User>();
    this.userService.clearParameter();
    this.userService.getAll().subscribe(success => {
      this.usersList = success
    })
  }

  delete(pk: any, description: string, callback?: (event) => void): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '275px',
      // height: '220px',
      data: {
        id: pk,
        title: 'Apagar',
        message: 'Retirar usuário da lista de cliente?',
        description: description
      }
    });

    dialogRef.afterClosed()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(result => {
          if (result) {
            this.customerService.update(this.id_client, {'users': this.usersList})
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                      this.toast.success(MESSAGES.success_title, MESSAGES.deleted_successfully);
                      this.getUsers();
                      if (callback) {
                        callback(EVENT.DELETE);
                      }
                    }
                );
          }
        });
  }

  userExistsInCustomer(user: User) {
    return user.users.includes(parseInt(this.id_client));
  }

  saveUserCustomer(checked: boolean, user: User) {
    this.customerService.clearParameter();
    this.formGroup.controls.customer.setValue(this.id_client);
    this.formGroup.controls.users.setValue(checked ? user : []);
    this.object = this.formGroup.getRawValue();
    let toSend: any;
    const formData = new FormData();
    if (checked) {
      Object.keys(this.object).forEach(key => {
        const value = this.object[key];
        if (value !== null && typeof value === 'object') {
          formData.append(key, value === undefined ? '' : value.id);
        } else {
          formData.append(key, value === null || value === undefined ? '' : value);
        }
      });
      toSend = formData;
    }else {
      toSend = this.object;
    }
    this.customerService.update(this.object['customer'], toSend).subscribe(success => {
      this.getUsers();
    });
  }

}
