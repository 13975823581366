<mat-toolbar class="mat-dialog-header">
    <span fxFlex>{{data?.title}}</span>
    <button mat-icon-button [mat-dialog-close]="false" tabindex="-1">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <span>{{data?.message}}</span>
    <strong>{{data?.description}}</strong>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-button mat-stroked-button [mat-dialog-close]="false" tabindex="1">
        Não
    </button>
    <button mat-button mat-raised-button color="warn" [mat-dialog-close]="true" tabindex="2">
        Sim
    </button>
</mat-dialog-actions>
