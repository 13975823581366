import * as moment from "moment";
import {MatPaginator} from "@angular/material/paginator";


export class Utils {

    public static getOffset(pageIndex: number, pageSize: number): number {
        return pageSize * pageIndex;
    }

    public static getDateFormatToString(date: string): string {
        if (date) {
            return moment(date, "YYYY-MM-DD HH:mm:ssZ").format("YYYY-MM-DD");
        } else {
            return null;
        }
    }

    public static maskDate($event): void {
        const mask = "99/99/9999";
        let value = $event.target.value.replace(/\D/g, "");
        const pad = mask.replace(/\D/g, "").replace(/9/g, "_");
        const valueMask = value + pad.substring(0, pad.length - value.length);
        let valueMaskPos = 0;
        value = "";
        for (let i = 0; i < mask.length; i++) {
            if (isNaN(parseInt(mask.charAt(i), 10))) {
                value += mask.charAt(i);
            } else {
                value += valueMask[valueMaskPos++];
            }
        }
        if (value.indexOf("_") > -1) {
            value = value.substr(0, value.indexOf("_"));
        }
        $event.target.value = value;
    }


    public static getDateToStringRequest(date: Date): string {
        let resultDate = null;
        if (date) {
            resultDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
        }
        return resultDate;
    }

    public static getDateFormatToView(date: string): string {
        return moment(date, "YYYY-MM-DD HH:mm:ssZ").format("DD/MM/YYYY");
    }

    public static getDateTimeFormatToView(date: string): string {
        return moment(date, "YYYY-MM-DD HH:mm:ssZ").format("DD/MM/YYYY HH:mm:ss");
    }

    public static getDateFromString(date: string | Date): Date {
        let resultDate = null;
        if (date) {
            const day = moment(date, "YYYY-MM-DD HH:mm:ssZ").date();
            const month = moment(date, "YYYY-MM-DD HH:mm:ssZ").month();
            const year = moment(date, "YYYY-MM-DD HH:mm:ssZ").year();
            resultDate = new Date(year, month, day);
        }
        return resultDate;
    }

    public static setPaginatorConfigurations(paginator: MatPaginator): void {
        paginator.pageIndex = 0;
        paginator.pageSize = 10;
        paginator.pageSizeOptions = [5, 10, 20, 30, 40];
    }

    public static downloadFileFromBlob(file: Blob, filename: string, insert_extension?: boolean): any {
        const xlsUrl = (window.URL || window["webkitURL"]).createObjectURL(
            new Blob([file], {type: "application/vnd.ms-excel"})
        );
        const anchor = document.createElement("a");
        anchor.download = filename;

        if (insert_extension) {
            anchor.download = `${filename}.xlsx`
        }

        anchor.href = xlsUrl;
        anchor.dispatchEvent(
            new MouseEvent("click", {bubbles: true, cancelable: true, view: window})
        );
        anchor.remove();
    }

    public static getUrlId(url: string): string {
        const urlSplit = url.split("/");
        return urlSplit[urlSplit.length - 2];
    }

    public static convertLocalDateToUTC(localDate: Date): Date {
        const modified_at = new Date(localDate);
        return new Date(modified_at.getTime() + (modified_at.getTimezoneOffset() * 60000));
    }
}
