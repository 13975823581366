import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {Customer, GroupType} from "../../models/models.model";
import {EChartOption} from "echarts";
import {MatButtonToggleGroup} from "@angular/material/button-toggle";
import {URLS} from "../../app.urls";
import {BaseComponent} from "../../base.component";
import {ResiduesPerCustomerChart} from "../../dtos/residues-per-custumert";
import {Utils} from "../../shared/utils";
import {map, startWith, takeUntil} from "rxjs/operators";
import {CustomerService, GroupTypeService} from "../../services/services.service";
import {Observable} from "rxjs";
import {Validators} from "@angular/forms";
import {SERVICE_TYPES} from "../../app.constant";
// tslint:disable-next-line:no-duplicate-imports
import * as _moment from 'moment';
// import {defaultFormat as _rollupMoment} from 'moment';
import {ResiduesPerCustomerMonthChart} from "../../dtos/residues-per-custumert-month";
import {AuthService} from "../../services/auth.service";

// const moment = _rollupMoment || _moment;


export const MONTH_YEAR_FORMAT = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

@Component({
  selector: 'app-residue-client-months',
  templateUrl: './residue-client-months.component.html',
  styleUrls: ['./residue-client-months.component.scss'],
})
export class ResiduesClientMonthsComponent extends BaseComponent<ResiduesPerCustomerChart> implements OnInit, AfterViewInit {

  public showChart = false;
  public weightOption: EChartOption;
  public theme = 'macarons';
  public dtInicial: any;
  public dtFinal: any;
  public operation: string;
  private color: string;
  public type = 'K';
  public customerList = new Array<Customer>();
  public filteredOptions: Observable<Customer[]>;
  public groupType = null;
  public groupTypes = new Array<GroupType>();
  public months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ']
  public user: any;
  private customer_chart: Customer;
  // R= valor / K = peso
  @ViewChild("typebuy", {static: false}) public typebuy: MatButtonToggleGroup;

  constructor(
    public injector: Injector,
    private customerService: CustomerService,
    private groupTypeService: GroupTypeService,
    private authService: AuthService,
  ) {
    super(injector, {endpoint: URLS.TICKET_PRODUCT});
  }

  ngOnInit(): void {
    this.user = this.authService.user;
    super.ngOnInit();
    this.getGroupProducts().subscribe(result => {
      this.groupTypes = result;
      this.groupType = result[1];
      this.getData();
    })
    this.getActiveCustomers().subscribe(success => {
      this.customerList = success;
    })
    this.operation = SERVICE_TYPES.COMPRA;
  }

  getCustomer() {
      this.customerService.getById(this.user.user_obj.users[0]).subscribe(result => {
        this.customer_chart = result
      })
  }

  getGroupProducts() {
    this.groupTypeService.clearParameter();
    this.groupTypeService.addParameter('active', true);
    return this.groupTypeService.getAll();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.user.user_obj.is_staff) {
      this.filteredOptions = this.formGroup.controls.customer.valueChanges
        .pipe(
          startWith(''),
          map(value => value ? this._filter(value) : this.customerList.slice())
        );
    } else {
      this.getCustomer();
    }
  }

  public changeType(value) {
    this.type = value
    this.getData();
  }

  public clearField(formControl) {
    formControl.setValue('')
  }

  public getData(): void {
    switch (this.groupType.name) {
      case 'LIXO':
        this.color = '#8f5e30';
        break;
      case 'PAPELÃO':
        this.color = '#2856a3';
        break;
      case 'PLASTICO':
        this.color = '#d12b17';
        break;
      case 'PLÁSTICO':
        this.color = '#d12b17';
        break;
      case 'METAIS':
        this.color = '#eab205';
        break;
      case 'PALLET':
        this.color = '#c3aa6d';
        break;
      default:
        this.color = '#00ff0c';
    }
    this.service.clearParameter();
    this.service.addParameter('group_type', this.groupType.id);
    this.service.addParameter('by_weight', this.type === 'K')
    this.service.addParameter('m_initial', this.formGroup.get('m_initial').value);
    this.service.addParameter('m_final', this.formGroup.get('m_final').value);
    this.service.addParameter('year', this.formGroup.get('year').value);

    if (!this.user.user_obj.is_staff) {
      this.service.addParameter('customer', this.user.user_obj.users[0]);
    } else if (this.formGroup.get('customer').value) {
      this.service.addParameter('customer', this.formGroup.get('customer').value.id);
      this.service.addParameter('op_type', this.operation);
    }
    if (this.dtInicial) {
      this.service.addParameter('dt_inicial', Utils.getDateToStringRequest(this.dtInicial._d));
    }
    if (this.dtFinal) {
      this.service.addParameter('dt_final', Utils.getDateToStringRequest(this.dtFinal._d));
    }
    this.service.getFromListRoute<ResiduesPerCustomerMonthChart[]>('residues_client_month')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.createChart(response);
      });
  }

  public createChart(response: ResiduesPerCustomerMonthChart[]): void {
    const dataAxis = [];
    const dataSeries = [];


    response.forEach(t => {
      dataAxis.push(this.months[t.ticket__date_weight_final__month-1]);
      dataSeries.push(t.total);
    });

    let unit = this.type === 'K' ? ' Kg' : ' R$';

    this.weightOption = {
      title: {show: true, text: this.user.user_obj.is_staff ? this.formGroup.get('customer').value.name : this.customer_chart.name},
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: function (params, ticket, callback) {
          return params[0].value.toLocaleString('pt') + unit;
        }
      },
      toolbox: {
        feature: {
          saveAsImage: {
            show: true,
            title: 'download'
          },
        },
        top: 'top',
        right: 15,
        orient: 'vertical',
        itemGap: 20
      },
      xAxis: {
        type: 'category',
        data: dataAxis,
      },
      yAxis: {
        type: 'value',
        splitArea: {
          show: true,
          areaStyle: {
            color: ['#f8f8f8', '#c7c3c3']
          }
        }
      },
      series: [{
        type: 'bar',
        data: dataSeries,
      }],
      color: [this.color],
    };

    this.showChart = response.length > 0;
  }

  createFormGroup(): void {
      this.formGroup = this.formBuilder.group({
        customer: [null, this.user.user_obj.is_staff ? null : Validators.required ],
        m_initial: [new Date().getMonth()],
        m_final: [new Date().getMonth()],
        year: [new Date().getFullYear()],
      });
  }

  getActiveCustomers() {
    this.customerService.clearParameter();
    this.customerService.addParameter('active', true);
    return this.customerService.getAll();
  }

  displayFn(customer?: Customer): string | undefined {
    return customer ? customer.name : undefined;
  }

  private _filter(name: string): Customer[] {
    let filterValue = name;
    if (typeof name === 'string') {
      filterValue = name.toLowerCase();
    }
    return this.customerList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
}
