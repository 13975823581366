<div fxLayout="row" fxFill>

    <div fxFlex="35" fxLayout="column" class="brand-block-login">

        <div fxFlex="50" fxLayoutAlign="center center">
            <img src="../../assets/images/gir_logo_login.svg" width="120px" height="177px" alt="">
        </div>
        <div fxFlex></div>
        <div fxFlex="160px" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="6px">
            <img src="../../assets/images/gir_logo_rodape_login.svg" width="34px" height="34px" alt="">
            <div fxLayout="column" fxLayoutAlign="center center" class="txt-login-footer">
                <span>Gerenciamento Integrado de Resíduos</span>
                <span>LB AMBIENTAL © 2020</span>
            </div>
        </div>

    </div>

    <div fxFlex fxLayoutAlign="center center">

        <div fxFlex="300px">
            <form [formGroup]="formGroup">
                <div fxLayout="column" fxLayoutGap="20px">
                    <mat-form-field fxFlex>
                        <mat-label>Usuário</mat-label>
                        <input matInput type="text" maxlength="64" formControlName="username" required autoFocus>
                        <mat-icon matSuffix class="login-icon">person</mat-icon>
                        <mat-error validator></mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex>
                        <mat-label>Senha</mat-label>
                        <input matInput type="password" minlength="4" maxlength="64" formControlName="password"
                               required>
                        <mat-icon matSuffix class="login-icon">lock</mat-icon>
                        <mat-error validator></mat-error>
                    </mat-form-field>

                    <div>
                        <button mat-raised-button type="submit" color="accent" [disabled]="!formGroup.valid"
                                (click)="login()">
                            {{message}}
                        </button>
                    </div>
                </div>
            </form>
        </div>

    </div>

</div>
