import {AfterViewInit, Directive, InjectionToken, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CurrencyMaskConfig} from 'ng2-currency-mask/src/currency-mask.config';
import {interval} from 'rxjs/internal/observable/interval';
import {Subject} from 'rxjs';
import {distinctUntilChanged, take, takeUntil} from 'rxjs/operators';
import {BaseService} from './services/base.service';
import {DialogComponent} from './shared/dialog/dialog.component';
import {Utils} from './shared/dialog/utils';
import {ToastService} from './services/toast.service';
import {MESSAGES, PT_CURRENCY_MASK_CONFIG} from './app.constant';
import {TitlePageService} from "./shared/title-page.service";

export interface BaseOptions {
  pk?: string;
  retrieveOnInit?: boolean;
  searchOnInit?: boolean;
  paramsOnInit?: {};
  noUpdateFormOnSave?: boolean;
  pagination?: boolean;
  pageSize?: number;
  endpoint: string;
  title?: string;
  nextRoute?: string;
  nextRouteUpdate?: string;
  keepFilters?: boolean;
}

export const EVENT = {
  RETRIEVE: 0,
  SAVE: 1,
  UPDATE: 2,
  DELETE: 3,
};

@Directive()
export abstract class BaseComponent<T> implements OnInit, OnDestroy, AfterViewInit {

  protected unsubscribe = new Subject();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public toast: ToastService;
  public title: TitlePageService;
  public dialog: MatDialog;
  public router: Router;
  public activatedRoute: ActivatedRoute;
  public http: HttpClient;
  public service: BaseService<T>;
  public dataSource: MatTableDataSource<T>;
  public formBuilder: FormBuilder;
  public formGroup: FormGroup;
  public object: T | {};
  public pk: string;
  public pageIndex = 0;
  public currencyMask: CurrencyMaskConfig;

  private isPlus = false;

  protected constructor(
    public injector: Injector,
    public options: BaseOptions
  ) {
    this.toast = injector.get(ToastService);
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.http = injector.get(HttpClient);
    this.formBuilder = injector.get(FormBuilder);
    this.title = injector.get(TitlePageService);
    this.service = injector.get(this.getInjectionToken());
    this.dataSource = new MatTableDataSource<T>();
    this.pk = options.pk || 'id';

    if (this.options.title) {
      this.title.name.next(this.options.title);
    }
  }

  public ngOnInit(callback?: () => void) {
    this.createFormGroup();

    if (this.options.retrieveOnInit) {
      this.beforeRetrieve(callback);
    }

    if (this.options.keepFilters && this.formGroup) {
      this.onKeepFilters();
    }

    if (this.options.pagination) {
      this.createPaginator();
    }

    if (this.options.searchOnInit) {
      this.search(this.paginator, this.sort, callback);
    }

    this.currencyMask = PT_CURRENCY_MASK_CONFIG;
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public abstract createFormGroup(): void;

  // convenience getter for easy access to form fields
  get f() {
    return this.formGroup.controls;
  }

  // convenience getter for easy access to form fields values
  get v() {
    return this.formGroup.value;
  }

  public createService<K>(model: new () => K, path: string): BaseService<K> {
    const TOKEN = new InjectionToken<BaseService<K>>('service_' + path, {
      providedIn: 'root', factory: () => new BaseService<K>(this.http, path),
    });
    return this.injector.get(TOKEN);
  }

  public createPaginator(): void {
    const pSize = localStorage.getItem('pageSize')
    this.options.pageSize = Number(pSize) ;
    if (this.options.pagination && this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = this.options.pageSize || 10;
      this.paginator.pageSizeOptions = [5, 10, 25, 50];
    }
  }

  public reloadPage(timeInterval: number): void {
    interval(timeInterval * 1000)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => window.location.reload());
  }

  public goToPage(route: string): void {
    this.router.navigate([route], {queryParamsHandling: 'merge'}).then();
  }

  public onResponse(response: T, event: number, callback?: (event) => void) {
    if (!response) {
      this.object = {};
      this.createFormGroup();
    } else if (!((event === EVENT.SAVE || event === EVENT.UPDATE) && this.options.noUpdateFormOnSave && !this.isPlus)) {
      this.object = response;
      if (this.formGroup) {
        this.formGroup.reset(this.object);
      }
    }
    if (callback) {
      callback(event);
    }
  }

  public onKeepFilters(): void {
    this.activatedRoute.queryParams
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe))
      .subscribe(params => {
        Object.keys(params).forEach(t => {
          if (t === 'p') {
            this.pageIndex = params[t];
          } else if (this.f[t]) {
            this.f[t].patchValue(params[t]);
          }
        });
      });
  }

  public beforeRetrieve(callback?: () => void): void {
    this.activatedRoute.params
      .pipe(take(1))
      .subscribe((params: Params) => {
        // tslint:disable-next-line: no-string-literal
        const action = params['action'];
        if (action && action !== 'new') {
          this.object[this.pk] = String(action);
          this.retrieve(this.object[this.pk], callback);
        }
      });
  }

  public retrieve(pk: any, callback?: () => void): void {
    if (this.options.paramsOnInit) {
      const parameters = this.options.paramsOnInit;
      Object.keys(parameters).forEach(t => {
        this.service.addParameter(t, parameters[t]);
      });
    }

    this.service.getById(pk)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => this.onResponse(response, EVENT.RETRIEVE, callback));
  }

  public search(event, sort?: Sort, callback?: () => void): void {
    if (this.options.pagination) {
      localStorage.setItem('pageSize', event.pageSize);
      this.paginator.pageIndex = (event instanceof MatPaginator) ? this.pageIndex : event.pageIndex;
      this.service.addParameter('limit', String(this.paginator.pageSize));
      this.service.addParameter('offset', String((this.paginator.pageIndex * this.paginator.pageSize)));

      if (sort && sort.direction) {
        this.service.addParameter('ordering', sort.direction === 'desc' ? '-' + sort.active : sort.active);
      }

      this.service.getPaginated()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(response => {
          this.dataSource.data = response.results;
          this.paginator.length = response.count;
          this.runCallback(callback);
        });
    } else {
      this.service.getAll()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(response => {
          this.dataSource.data = response;
          this.runCallback(callback);
        });
    }

    if (this.options.keepFilters) {
      const queryParams = {};
      Object.keys(this.v).forEach(t => queryParams[t] = this.v[t] ? this.v[t] : '');
      // queryParams['p'] = this.paginator.pageIndex;
      this.router.navigate([], {relativeTo: this.activatedRoute, queryParams: queryParams}).then();
    }
  }

  public saveOrUpdateFormData(callback?: (event) => void): void {
    const formData = new FormData();
    Object.keys(this.v).forEach(key => {
      const value = this.v[key];
      if (Array.isArray(value)) {
        value.forEach(item => {
          formData.append(key, item === undefined ? '' : item.id);
        })
      } else if (value !== null && typeof value === 'object') {
        formData.append(key, value === undefined ? '' : value.id);
      } else {
        formData.append(key, value === null || value === undefined ? '' : value);
      }
    });
    if (this.object[this.pk]) {
      this.update(this.object[this.pk], formData, false, callback);
    } else {
      this.save(formData, false, callback);
    }
  }

  public saveOrUpdateFormDataPlus(callback?: (event) => void): void {
    const formData = new FormData();
    Object.keys(this.v).forEach(key => {
      const value = this.v[key];
      formData.append(key, value === null || value === undefined ? '' : value);
    });
    if (this.object[this.pk]) {
      this.update(this.object[this.pk], formData, true, callback);
    } else {
      this.save(formData, true, callback);
    }
  }

  public saveOrUpdate(callback?: (event) => void): void {
    Object.assign(this.object, this.v);
    if (this.object[this.pk]) {
      this.update(this.object[this.pk], this.object, false, callback);
    } else {
      this.save(this.object, false, callback);
    }
  }

  public saveOrUpdatePlus(callback?: (event) => void): void {
    Object.assign(this.object, this.v);
    if (this.object[this.pk]) {
      this.update(this.object[this.pk], this.object, true, callback);
    } else {
      this.save(this.object, true, callback);
    }
  }

  private save(aObject, plus: boolean, callback?: (event) => void): void {
    this.service.save(aObject)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          this.toast.success(MESSAGES.success_title, MESSAGES.saved_successfully);
          if (plus) {
            this.onResponse(null, EVENT.SAVE, callback);
          } else {
            this.onResponse(response, EVENT.SAVE, callback);
            if (this.options.nextRoute) {
              this.goToPage(this.options.nextRoute);
            }
          }
        }
      );
  }

  private update(pk: any, aObject, plus: boolean, callback?: (event) => void): void {
    this.service.update(pk, aObject)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          this.toast.success(MESSAGES.success_title, MESSAGES.updated_successfully);
          if (plus) {
            this.onResponse(null, EVENT.UPDATE, callback);
          } else {
            this.onResponse(response, EVENT.UPDATE, callback);
            if (this.options.nextRoute) {
              this.goToPage(this.options.nextRoute);
            } else if (this.options.nextRouteUpdate) {
              this.goToPage(this.options.nextRouteUpdate);
            }
          }
        }
      );
  }

  public delete(pk: any, description: string, callback?: (event) => void): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '275px',
      // height: '220px',
      data: {
        id: pk,
        title: 'Apagar',
        message: 'Deletar ',
        description: description
      }
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(result => {
        if (result) {
          this.service.delete(pk)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.toast.success(MESSAGES.success_title, MESSAGES.deleted_successfully);
                this.search(this.paginator);
                if (callback) {
                  callback(EVENT.DELETE);
                }
              }
            );
        }
      });
  }

  public toggle(aObject: T, field: any, callback?: () => void): void {
    const patch = {};
    patch[field] = aObject[field];

    this.service.update(aObject[this.pk], patch)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          this.toast.success(MESSAGES.success_title, MESSAGES.updated_successfully);
          this.runCallback(callback);
        },
        () => {
          aObject[field] = !aObject[field];
        }
      );
  }

  public csvExport(): void {
    this.service.generateFileReport('export', {})
      .subscribe(response => {
        Utils.downloadFileFromBlob(response, this.getCsvFileName());
      }, () => null);
  }

  private getCsvFileName(): string {
    let filename = `${Utils.nowStr('DDMMYYYY_HHmmss')}.csv`;
    try {
      const split = this.options.endpoint.split('/');
      const model = split[split.length - 2];
      filename = model.concat(filename);
    } catch (e) {
    }
    return filename;
  }

  private getInjectionToken(): InjectionToken<BaseService<T>> {
    return new InjectionToken<BaseService<T>>('service_' + this.options.endpoint, {
      providedIn: 'root', factory: () => new BaseService<T>(this.http, this.options.endpoint),
    });
  }

  private runCallback(callback?: () => void) {
    if (callback) {
      callback();
    }
  }

  ngAfterViewInit(): void {

  }

}
