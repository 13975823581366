import {Component, Injector, OnDestroy} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {BaseComponent} from "../../base.component";
import {Customer} from "../../models/models.model";
import {URLS} from "../../app.urls";
import {TitlePageService} from "../../shared/title-page.service";

@Component({
    selector: 'app-client',
    templateUrl: './client.component.html',
    styleUrls: ['./client.component.scss']
})
export class ClientComponent extends BaseComponent<Customer> implements OnDestroy {

    public object: Customer = new Customer();

    constructor(
        private fb: FormBuilder,
        public injector: Injector,
    ) {
        super(
            injector, {
                pk: 'id',
                endpoint: URLS.CUSTOMER,
                nextRoute: '/client-list',
                nextRouteUpdate: '/client-list',
                retrieveOnInit: true,
                title: 'Informações do cliente'
            });
    }

    public createFormGroup(): void {
        this.formGroup = this.fb.group({
            name: [null, Validators.required],
            address: [null, Validators.required],
            phone: [null],
            cnpj: [null, Validators.required],
            municipal_registration: [null],
            state_registration: [null, Validators.required]
        });

    }

}
