<!-- Colored FAB button -->
<button mat-fab id="add" [routerLink]="['new']">
    <i class="material-icons">add</i>
</button>

<div fxLayout="column" fxLayoutGap="20px">

    <form [formGroup]="formGroup" (submit)="search(paginator, sort)">
        <div fxLayout="row" fxLayoutAlign="start start">
            <mat-form-field>
                <input id="name_search" matInput placeholder="Pesquisar" formControlName="name">
            </mat-form-field>
            <button mat-icon-button color="primary" class="bt-search" type="submit">
                <i class="material-icons">search</i>
            </button>
        </div>
    </form>

    <div class="mat-elevation-z1">
        <table #licenseTable mat-table class="full-width-table" matSort aria-label="Elements">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Licença</th>
                <td mat-cell *matCellDef="let row"><a [routerLink]="[row.id]"> {{row.number}}-{{row.name}}</a></td>
            </ng-container>
            <!-- Id Column -->
            <ng-container matColumnDef="dt_due">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Válido até</th>
                <td mat-cell *matCellDef="let row">{{row.dt_due | date:'shortDate'}}</td>
            </ng-container>

            <ng-container matColumnDef="file">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Arquivo</th>
                <td mat-cell *matCellDef="let row">
                    <a target="_blank" *ngIf="row.licenses_file.length"
                       [href]="row.licenses_file.length? row.licenses_file[0].file : ''">
                        <mat-icon>folder_open</mat-icon>
                    </a>
                </td>
            </ng-container>
            <!-- Name Column -->

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let element" align="end">
                    <button mat-button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="delete(element.id, element.name)">
                            <mat-icon>delete</mat-icon>
                            <span>Remover</span>
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator #paginator [length]="paginator.length" [pageSize]="paginator.pageSize"
                       [pageSizeOptions]="paginator.pageSizeOptions" (page)="search($event, sort)">
        </mat-paginator>
    </div>

    <div fxLayout="column" fxLayoutGap="15px" *ngIf="!user.is_staff">
        <div class="row">
            <div class="col">
                <p>LICENÇAS RELACIONADAS</p>
                <div class="line"></div>
            </div>
        </div>

        <div class="mat-elevation-z1">
            <table mat-table class="full-width-table" [dataSource]="relatedLicensesList" matSort aria-label="Elements">
                <ng-container matColumnDef="customer_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Empresa</th>
                    <td mat-cell *matCellDef="let row">{{row.customer_name}}</td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Licença</th>
                    <td mat-cell *matCellDef="let row">{{row.number}}-{{row.name}}</td>
                </ng-container>
                <!-- Id Column -->
                <ng-container matColumnDef="dt_due">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Válido até</th>
                    <td mat-cell *matCellDef="let row">{{row.dt_due | date:'shortDate'}}</td>
                </ng-container>

                <ng-container matColumnDef="file">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Arquivo</th>
                    <td mat-cell *matCellDef="let row">
                        <a target="_blank" *ngIf="row.licenses_file.length"
                           [href]="row.licenses_file.length? row.licenses_file[0].file : ''">
                            <mat-icon>folder_open</mat-icon>
                        </a>
                    </td>
                </ng-container>
                <!-- Name Column -->

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell *matCellDef="let element" align="end">
                        <button mat-button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="delete(element.id, element.name)">
                                <mat-icon>delete</mat-icon>
                                <span>Remover</span>
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsRelations"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsRelations;"></tr>
            </table>

            <mat-paginator #paginator [length]="relatedLicensesList.length" [pageSize]="paginator.pageSize"
                           [pageSizeOptions]="paginator.pageSizeOptions" (page)="search($event, sort)">
            </mat-paginator>
        </div>

    </div>

</div>
