import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from "../base.component";
import {License} from "../models/models.model";
import {URLS} from "../app.urls";
import {MatTable} from "@angular/material/table";
import { AuthService } from '../services/auth.service';
import { CustomerViewLicenseService, LicenseService } from '../services/services.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-license-list',
  templateUrl: './license-list.component.html',
  styleUrls: ['./license-list.component.scss']
})
export class LicenseListComponent extends BaseComponent<License>  {
  @ViewChild('licenseTable') table: MatTable<License>;

  public user: any;
  public relatedLicensesList: Array<License>;

  displayedColumns = ['name', 'dt_due','file','action'];
  displayedColumnsRelations = ['customer_name', 'name', 'dt_due','file','action'];
  constructor(
      public injector: Injector,
      private authService: AuthService,
      private clientViewLicenseService: CustomerViewLicenseService
  ) {
    super(injector, {endpoint: URLS.LICENSE, pagination: true, searchOnInit: false, keepFilters: true, title: 'Licenças'});
  }

  ngOnInit() {
    this.user = this.authService.user
    this.createFormGroup();
    if (!this.user.is_staff) {
      this.relatedLicensesList = new Array<License>();
      this.searchRelated();
    }
  }

  ngAfterViewInit() {
    this.search(this.paginator, this.sort);
    this.dataSource.sort = this.sort;
    this.table.dataSource = this.dataSource;
  }

  public createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      name: [null],
    });
  }

  public searchRelated(): void {
    this.relatedLicensesList = new Array<License>();
    this.clientViewLicenseService.clearParameter();
    this.clientViewLicenseService.addParameter('customer_view', this.user.user_obj.users[0]);
    this.clientViewLicenseService.getAll().subscribe( success => {
      success.forEach(element => {
        this.service.clearParameter();
        this.service.addParameter('customer', null);
        this.service.addParameter('active', true)
        this.service.getAll().subscribe( success => this.relatedLicensesList = this.relatedLicensesList.concat(success));
      });
    });
  }

  public search(event, sort): void {
    this.service.clearParameter();
    if (!this.user.is_staff) {
      this.service.addParameter('customer', this.user.user_obj.users[0]);
      this.service.getAll().
      pipe(takeUntil(this.unsubscribe))
          .subscribe(response => {
            this.dataSource.data = response;
            this.paginator.length = response.length;
          });
    } else {
      this.service.addParameter("customer", null);
      this.service.addParameters(this.formGroup);
      super.search(event, sort);
    }
  }

}
