import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {MatTable} from '@angular/material/table';
import {BaseComponent} from "../base.component";
import {Customer} from "../models/models.model";
import {URLS} from "../app.urls";
import {TitlePageService} from "../shared/title-page.service";

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent extends BaseComponent<Customer> implements AfterViewInit, OnInit {
  @ViewChild(MatTable) table: MatTable<Customer>;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name', 'phone', 'cnpj', 'action'];

  constructor(
      public injector: Injector,
  ) {
    super(injector, {endpoint: URLS.CUSTOMER, pagination: true, searchOnInit: true, keepFilters: true, title: 'Clientes'});
  }


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.table.dataSource = this.dataSource;
  }

  public createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      customer: [null],
    });
  }

  public search(event, sort): void {
    this.service.clearParameter();
    if (this.v.customer) {
      this.service.addParameter('customer', this.v.customer);
    }
    super.search(event, sort);
  }
}
