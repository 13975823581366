<div fxLayout="column" fxLayoutGap="20px">

    <div class="title" *ngIf="this.id_client">Usuários para Inclusão</div>

    <div class="mat-elevation-z1">
        <table mat-table class="full-width-table" [dataSource]="usersList" matSort aria-label="Elements">

            <!-- Name Column -->
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuário</th>
                <td mat-cell *matCellDef="let row">{{row.username}}</td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="included">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Inclusão</th>
                <td mat-cell *matCellDef="let row"><mat-checkbox [checked]="userExistsInCustomer(row)" (change)="saveUserCustomer($event.checked, row)" ></mat-checkbox></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>5
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator #paginator
                       [length]="usersList.length"
                       [pageIndex]="0"
                       [pageSize]="50"
                       [pageSizeOptions]="[25, 50, 100, 250]">
        </mat-paginator>
    </div>
</div>

