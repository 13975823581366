import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {TicketListComponent} from './ticket-list/ticket-list.component';
import {TicketComponent} from './ticket-list/ticket/ticket.component';
import {LoginComponent} from "./login/login.component";
import {AppGuard} from "./app.guard";
import {MainMenuComponent} from "./main-menu/main-menu.component";
import {ClientListComponent} from "./client-list/client-list.component";
import {ClientComponent} from "./client-list/client/client.component";
import {TablePriceComponent} from "./client-list/table-price/table-price.component";
import {ProductListComponent} from "./product-list/product-list.component";
import {ProductComponent} from "./product-list/product/product.component";
import {LicenseListComponent} from "./license-list/license-list.component";
import {LicenseComponent} from "./license-list/license/license.component";
import {ClientTruckComponent} from "./client-list/client-truck/client-truck.component";
import {ClientLicenseComponent} from "./client-list/client-license/client-license.component";
import {DestinationCertComponent} from "./destination-cert/destination-cert.component";
import {TicketsMonthsComponent} from "./tickets-months/tickets-months.component";


const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {
    path: '',
    component: MainMenuComponent,
    children: [
      {path: '', redirectTo: "home", pathMatch: "full"},
      {path: 'home', component: DashboardComponent, canActivate: [AppGuard]},
      {
        path: 'ticket-list',
        children: [
          {
            path: '',
            component: TicketListComponent,
            canActivate: [AppGuard]
          },
          {
            path: ':action',
            component: TicketComponent,
            canActivate: [AppGuard]
          },
        ],
      },
      {
        path: 'client-list',
        children: [
          {
            path: '',
            component: ClientListComponent,
            canActivate: [AppGuard]
          },
          {
            path: ':action',
            component: ClientComponent,
            canActivate: [AppGuard]
          },
        ],
      },
      {
        path: 'product-list',
        children: [
          {
            path: '',
            component: ProductListComponent,
            canActivate: [AppGuard]
          },
          {
            path: ':action',
            component: ProductComponent,
            canActivate: [AppGuard]
          },
        ],
      },
      {
        path: 'license-list',
        children: [
          {
            path: '',
            component: LicenseListComponent,
            canActivate: [AppGuard]
          },
          {
            path: ':action',
            component: LicenseComponent,
            canActivate: [AppGuard]
          },
        ],
      },
      {
        path: 'my-license-list',
        children: [
          {
            path: '',
            component: ClientLicenseComponent,
            canActivate: [AppGuard]
          },
        ],
      },
      {
        path: 'default-price',
        children: [
          {
            path: ':action',
            component: TablePriceComponent,
            canActivate: [AppGuard]
          },
        ],
      },
      {
        path: 'certificate',
        children: [
          {
            path: '',
            component: DestinationCertComponent,
            canActivate: [AppGuard]
          },
        ],
      },
      {
        path: 'ticket_months',
        children: [
          {
            path: '',
            component: TicketsMonthsComponent,
            canActivate: [AppGuard]
          },
        ],
      },
    ]

  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
