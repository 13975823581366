<div fxLayout="column" fxLayoutGap="20px">

    <div class="title" *ngIf="this.id_client">Tabela de preço</div>

    <form novalidate [formGroup]="formGroup">
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <input matInput type="text" placeholder="Resíduo" formControlName="residue"
                       [matAutocomplete]="auto2">
                <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFnR">
                    <mat-option *ngFor="let option of filteredResidues | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field fxFlex>
                <input currencyMask max="9999999999.99" matInput type="text" formControlName="price"
                       placeholder="Preço por Kg (compra)" id="price">
            </mat-form-field>
            <mat-form-field fxFlex>
                <input currencyMask matInput type="text" formControlName="price_sell"
                       placeholder="Preço por Kg (venda)" id="price_sell">
            </mat-form-field>
            <div>
                <button mat-icon-button mat-raised-button color="accent" id="add_table" type="button"
                        (click)="savePrice()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div>
                <button mat-button color="accent" type="button" (click)="generateAll()">Todos os produtos</button>
            </div>
        </div>
    </form>

    <div class="mat-elevation-z1">
        <table mat-table class="full-width-table" [dataSource]="dataSource" matSort aria-label="Elements">

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Resíduo</th>
                <td mat-cell *matCellDef="let row">{{row.residue_name}}</td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Valor por Kg (compra)</th>
                <td mat-cell *matCellDef="let row">
                    <mat-form-field appearance="fill">
                        <input currencyMask matInput (keypress)="tab($event)" (blur)="savePrice(row)"
                               [(ngModel)]="row.price">
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="price_sell">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Valor por Kg (venda)</th>
                <td mat-cell *matCellDef="let row">
                    <mat-form-field appearance="fill">
                        <input currencyMask matInput (keypress)="tab($event)" (blur)="savePrice(row)"
                               [(ngModel)]="row.price_sell">
                    </mat-form-field>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator #paginator
                       [length]="dataSource?.data.length"
                       [pageIndex]="0"
                       [pageSize]="50"
                       [pageSizeOptions]="[25, 50, 100, 250]">
        </mat-paginator>
    </div>

</div>
